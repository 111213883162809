import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WebserviceService } from "../api/webservice.service";

@Component({
  selector: "app-libraryedit",
  templateUrl: "./libraryedit.component.html",
  styleUrls: ["./libraryedit.component.scss"],
})
export class LibraryeditComponent implements OnInit {
  loginData: any;
  edit_library: any;
  created_by_id: any = "";
  created_by: any = "";
  property_id: any = "";
  property_name: any = "";
  title: any = "";
  short_descriptions: any = "";
  library_id: any = "";
  _id: any = "";
  pdf_file_type: any = "";

  type: any = "pdf";
  subject: any = "";
  websitelink: any = "";
  pdf: any = "";
  file: any;
  constructor(
    private router: Router,
    public webservice: WebserviceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.edit_library = params["_id"];
      console.log("edit=", this.edit_library);
    });
    this.loginData = JSON.parse(localStorage.getItem("LoginToken"));
    this.detail();
  }

  onChangeType(val) {
    console.log(val);
    this.type = val;
  }

  onFileChange(event) {
    if (!event.target.files.length) return;
    this.file = event.target.files[0];
    console.log('type',this.file)
    if (
     
      this.file.type == "application/pdf" 
      
    ) {
      this.pdf_file_type = event.target.files[0].type.split("/")[0];
     
      const reader = new FileReader();
      reader.onload = (e) => {
        //console.log(e.target.result)
        this.pdf = reader.result.toString();
      };
      reader.readAsDataURL(this.file);
    } else {
      this.toastr.error("Invalid format. Please upload PDF.");
    }
  }

  detail() {
    this.spinner.show();
    this.webservice.getData("getLibraryById/" + this.edit_library).then(
      (result: any) => {
        if (result.status == 1) {
          // this.created_by_id= result.data._id;
          // this.created_by= result.data.name;
          //  this. property_id= result.data.property_id;
          //   this.property_name= result.data.property_name;
          this.subject = result.data.title;
          this.short_descriptions = result.data.short_descriptions;

          if(result.data.type=='1'){
            this.type= 'pdf'
            this.pdf = result.data.library_pdf;
          }else if(result.data.type=='2'){
            this.type= 'link'
            this.websitelink = result.data.web_link_show;
          }
          // this.pdf = result.data.library_pdf;
          // this.websitelink = result.data.web_link_show;
          this._id = result.data._id;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }
  onUpdate() {
    console.log("file: ", this.file);
    if (this.type == 'pdf'){
    if (this.subject == "") {
      this.toastr.error("Enter library subject");
    } else if (this.short_descriptions == "") {
      this.toastr.error("Enter library description");
    } else if (this.pdf == "") {
      this.toastr.error("Attach PDF file");
    }
    // else if ( this.pdf_file_type != "application"){
    //   console.log('file======',this.pdf_file_type)
    //      this.toastr.error('Please upload correct File Format');
    //    }
    else {
      this.spinner.show();
      let fd = new FormData();
      fd.append("created_by_id", this.loginData._id);
      fd.append("created_by", this.loginData.name);
      fd.append("property_id", this.loginData.property_id);
      fd.append("property_name", this.loginData.property_name);
      fd.append("title", this.subject);
      fd.append("library_pdf", this.file);
      fd.append("short_descriptions", this.short_descriptions);
      fd.append("library_id", this._id);
      console.log(fd);
      this.webservice.postData("updateLibraryPDF", fd).then(
        (result: any) => {
          if (result.status == 1) {
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
    } }else {
      if (this.subject == "") {
        this.toastr.error("Please enter library subject");
      } else if (this.short_descriptions == "") {
        this.toastr.error("enter library description");
      } else if (this.websitelink == "") {
        this.toastr.error("Please enter library link");
      } else {
        var params = {
          web_link: this.websitelink,
          created_by_id: this.loginData._id,
          created_by: this.loginData.name,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
          title: this.subject,
          short_descriptions: this.short_descriptions,
          library_id: this._id,
        };
        {
          this.spinner.show();
          this.webservice.postData("updateLibraryLink", params).then(
            (result: any) => {
              if (result.status == 1) {
                this.toastr.success(result.msg);
              } else {
                this.toastr.error(result.msg);
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
            }
          );
        }
      }
    }
  }
  
}
