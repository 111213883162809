import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forget-passowrd-page',
  templateUrl: './forget-passowrd-page.component.html',
  styleUrls: ['./forget-passowrd-page.component.scss']
})
export class ForgetPassowrdPageComponent implements OnInit {
  newPassword: any = '';
  confirmPassword: any = '';

  visible:boolean = true;
  changetype:boolean =true;
  visible1:boolean = true;
  changetype1:boolean =true;

  params:any;
  constructor( private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService, public webservice: WebserviceService,) {
      this.params= this.activatedRoute.snapshot.queryParams['id'];
      console.log(this.params);
  
   }

  ngOnInit() {
  }
  checkPassword() {

    var pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]{8,})$/;
   
    if (this.newPassword == '') {
      this.toastr.error('Please enter new password');
    }
    else if (this.confirmPassword == '') {
      this.toastr.error('Please enter confirm password');
    }
    else if (this.newPassword != this.confirmPassword) {
      this.toastr.error('Confirm new Password does not match');
    } 
    else if (!pass.test(this.newPassword)) {
      this.toastr.error('Passwords must be atleast 8 characters long with a combination of uppercase and lowercase letters and atleast one number');
    } 
    else { 
      var data = {
        id:this.params,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
    };
    this.spinner.show();
      this.webservice.postData('reset-password/'+this.params, data).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
          this.router.navigateByUrl('/login');
          this.newPassword = '';
          this.confirmPassword = '';
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  viewpass1(){
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1;
  }
      
    }

