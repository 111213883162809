import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  loginData: any;

  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  }
  // onLogout(){
    
  //   var params = {
  //     device_id: 'deviceId',
  //     user_id: this.loginData._id
  //   };
    
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     this.webservice.postData('userLogout', params).then((res: any) => {
      
  //       console.log('result: ',res); return

  //       if(res.status == 1) {
  //         localStorage.removeItem('LoginToken');
          
  //         this.router.navigate(['login']);
  //       } else {
  //         this.toastr.error(res.msg);
  //       }
  //       this.spinner.hide();
  //     }, (err) => {
  //       this.spinner.hide();
  //       console.log(err);
  //     });
  //     if (result.isConfirmed) {
       
  //     }
  //   })
  //   // this.spinner.show()
  //   // this.webservice.postData('userLogout', params).then((result: any) => {
      
  //   //   console.log('result: ',result);
  //   //   if(result.status == 1) {
  //   //     localStorage.removeItem('LoginToken');
  //   //     this.toastr.success(result.msg);
  //   //     this.router.navigate(['login']);
  //   //   } else {
  //   //     this.toastr.error(result.msg);
  //   //   }
  //   //   this.spinner.hide();
  //   // }, (err) => {
  //   //   this.spinner.hide();
  //   //   console.log(err);
  //   // });

  // }
  onLogout(){
    {
    
        var params = {
        device_id: 'deviceId',
         user_id: this.loginData._id
        };
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Logout?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.webservice.postData('userLogout', params).then((result: any) => {
      
              console.log('result: ',result);
              if(result.status == 1) {
                localStorage.removeItem('LoginToken');
                this.toastr.success(result.msg);
                this.router.navigate(['login']);
              } else {
                this.toastr.error(result.msg);
              }
              this.spinner.hide();
            }, (err) => {
              this.spinner.hide();
              console.log(err);
            });
      }
    })



  }
   
  };


}
