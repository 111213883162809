import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-visitor-e-pass',
  templateUrl: './visitor-e-pass.component.html',
  styleUrls: ['./visitor-e-pass.component.scss']
})
export class VisitorEPassComponent implements OnInit {
  loginData: any;
  list: any = [];

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getEpasses/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  onApprove(id) {
    var params = {
      pass_id: id
    }
    this.webservice.postData('approvePass',params).then((result: any) => {
      if(result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onReject(id, cp_id) {
    var params = {
      pass_id: id,
      sender_id: this.loginData._id,
      receiver_id: cp_id
    }
    this.webservice.postData('rejectEPass',params).then((result: any) => {
      if(result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

}
