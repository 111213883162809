import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  type: any = '';
  loginData: any = [];
  suiteno: any = '';
  name: any = '';
  comment: any = ''
  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.loginData);
    this.suiteno = this.loginData.suite_no;
    this.name =this.loginData.name;

  }

  onChangeType(val) {
    this.type = val;
    console.log(this.type)
  }

  onSend(){
    if (this.comment == '') {
      this.toastr.error('Please enter your comment');
    } 
    // else if (this.suiteno == '') {
    //   this.toastr.error('Please enter suite number');
    // } 
    else if (this.type = '') {
      this.toastr.error('Please select how would you like us to response');
    } else {
      var params = {
        user_id: this.loginData._id,
        user_name: this.loginData.name,
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        feedback: this.comment,
        suite_no: this.suiteno,
        response_medium: this.type,
        name:this.name
      }
      console.log(params);
      this.spinner.show();
      this.webservice.postData('saveFeedback', params).then((result: any) => {
        if (result.status == 1) {
          this.type = '';
          this.comment = '';
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();

      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }

  }

}
