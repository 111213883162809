import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-libray',
  templateUrl: './libray.component.html',
  styleUrls: ['./libray.component.scss']
})
export class LibrayComponent implements OnInit {
  loginData: any = [];
  type: any = 'pdf';
  subject: any = '';
  websitelink: any = '';
  pdf: any = '';
  file:any;
  short_descriptions:any ='';
  pdf_file_type:any;
  

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  }

  onChangeType(val) {
    console.log(val);
    this.type = val;

  }

  onUpdate() {
    if (this.type == 'pdf') {
      if(this.subject == '') {
        this.toastr.error('Please enter library subject');
      }else if(this.short_descriptions == '') {
        this.toastr.error('Please enter short descriptions');
      }
       else if(this.pdf == '') {
        this.toastr.error('Please attach pdf');
      }  else if ( this.pdf_file_type != "application"){
          console.log('file======',this.pdf_file_type)
             this.toastr.error('Please upload correct file format');
           }
      else {
        this.spinner.show();
        let fd=new FormData();
        fd.append('created_by_id',this.loginData._id);
        fd.append('created_by',this.loginData.name);
        fd.append('property_id',this.loginData.property_id);
        fd.append('property_name',this.loginData.property_name);
        fd.append('title',this.subject);
        fd.append('library_pdf',this.file);
        fd.append('short_descriptions',this.short_descriptions);
        this.webservice.postData('saveLibraryPDF', fd).then((result: any) => {
          if(result.status == 1) {
            this.subject = '';
            this.pdf = '';
            this.short_descriptions='';
            this.toastr.success(result.msg);
            this.router.navigate(['/library/libraryList']);

          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          console.log(err);
        });
      }

    } 
    else {
      if (this.subject == '') {
        this.toastr.error('Please enter library subject');
      } else if(this.short_descriptions== ''){
        this.toastr.error('Please enter short description');
      }
      else if (this.websitelink == '') {
        this.toastr.error('Please enter library link');
      } 
      else {
        var params = {
          web_link: this.websitelink,
          created_by_id: this.loginData._id,
          created_by: this.loginData.name,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
          title: this.subject,
          short_descriptions:this.short_descriptions,
        }
        this.spinner.show();
        this.webservice.postData('saveLibraryLink', params).then((result: any) => {
          if(result.status == 1) {
            this.subject = '';
            this.short_descriptions = '';
            this.websitelink = '';
            this.router.navigate(['/library/libraryList']);
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          console.log(err);
        });

      }

    }

  }


  onFileChange(event) {

    if(!event.target.files.length) return;
  this.file = event.target.files[0];
  if (this.file.type ==  "application/pdf") {
    this.pdf_file_type= event.target.files[0].type.split('/')[0]
  const reader = new FileReader();
  reader.onload = e =>{
    //console.log(e.target.result)
    this.pdf = reader.result.toString();
  };
  reader.readAsDataURL(this.file);
  }
  else{
    this.toastr.error("Invalid format. Please upload PDF.")
  }

}
}
