import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-property-manager-message-list',
  templateUrl: './property-manager-message-list.component.html',
  styleUrls: ['./property-manager-message-list.component.scss']
})
export class PropertyManagerMessageListComponent implements OnInit {
  list: any;
  loginData: any;
  search: any = '';
  noDatafound: boolean = true;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getResidentForPropertyManager/' + this.loginData.property_id+'/undefined/'+ this.loginData._id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        this.noDatafound = true;
        // this.noData = false;
        console.log("content: ", this.list);
      } else {
        this.noDatafound = false;
      }
      this.spinner.hide();
    }, (err) => {
      this.noDatafound = false;
      this.spinner.hide();
      console.log(err);
    });
  }

  onSearch(val) {
    if (val != '') {
        this.webservice.getData('getResidentForPropertyManager/' + this.loginData.property_id+'/'+val+'/'+this.loginData._id).then((result: any) => {
          if (result.status == 1) {
            this.list = result.data;
            this.noDatafound = true;
            console.log("content: ", this.list);
          } else {
            this.noDatafound = false;
          }
        }, (err) => {
          this.noDatafound = false;
          // this.webservice.presentToast(err);
          console.log(err);
        });

    } else {
      this.ngOnInit();
    }
  }

  chatdetails_Page(name, id) {
    let navihationExtras: NavigationExtras = {
      queryParams: {
        reciverId: id,
        nickname: name
      }
    }
    this.router.navigate(['home/property-manager-message'], navihationExtras)

  }
  getUserTypeDescription(userType: number): string {
    switch (userType) {
      case 3:
        return '(Staff)';
      case 5:
        return '(Vendor)';
      default:
        return ''; // Return an empty string if the user_type does not match the cases
    }
  }

}
