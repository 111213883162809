import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  loginData: any;
  list: any = [];
  website_link:any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('resourceList/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
      } else {
        console.log(result.msg);
      }

      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  checkpdf(pdf){
    
    var pdf_type= pdf.substring(pdf.lastIndexOf('/') + 1);
    console.log(pdf_type)
    if(pdf_type === 'undefined' || pdf_type === undefined || pdf_type === null || pdf_type === 'null' || pdf_type === ''){
      console.log('false============')
      return false
    }else if( pdf_type !='undefined'){
      console.log('true============')
      return true
    }
  }
  openlink(link){
    console.log(link)

    //window.open('https://www.youtube.com/' ,'_self')
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
            console.log("The link has http or https.");
            //window.open(link ,'_system','location=yes')
            let target = "_self";
             window.open(link, 'target ');

        }
        else{
            console.log("The link doesn't have http or https.");
            //this.webservice.presentToast('Not a proper link');
            var newlink = 'https://' + link
            let target = "_self";
           
             window.open(newlink , 'target ');
        }
      }
      shouldShowDetails(item: any): boolean {
        return (item.video_link && item.video_link.trim() !== '') || 
               (item.ecom_link && item.ecom_link.trim() !== '') ||
               (item.pdf_file && item.pdf_file.trim() !== '' && this.checkpdf(item.pdf_file));
      }
      

 
}
