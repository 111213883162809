import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as Moment from 'moment';

@Component({
  selector: 'app-memo-details',
  templateUrl: './memo-details.component.html',
  styleUrls: ['./memo-details.component.scss']
})
export class MemoDetailsComponent implements OnInit {
  loginData: any;
  date: any;
  list: any = [];

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.date = this.activatedRoute.snapshot.paramMap.get('date');
    console.log(this.date);
    var date = Moment(this.date).format("YYYY-MM-DD");
    this.spinner.show();
    this.webservice.getData('getMemoDiary/'+this.loginData.property_id+'/'+ date).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
