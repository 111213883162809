import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AddswimmingpoolComponent } from './addswimmingpool/addswimmingpool.component';
import { ResourcesComponent } from './resources/resources.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
// import { RouterModule, Routes } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { WebserviceService } from './api/webservice.service';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ServicesComponent } from './services/services.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DiaryComponent } from './diary/diary.component';
import { ResidentpostingComponent } from './residentposting/residentposting.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { CommentComponent } from './comment/comment.component';
import { ResidentdirectoryComponent } from './residentdirectory/residentdirectory.component';
import { ResidentDetailsComponent } from './resident-details/resident-details.component';
import { ManageMemoComponent } from './manage-memo/manage-memo.component';
import { MemoDetailsComponent } from './memo-details/memo-details.component';
import { AddmemoComponent } from './addmemo/addmemo.component';
import { SettingComponent } from './setting/setting.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { AnnouncementAddComponent } from './announcement-add/announcement-add.component';
import { AnnouncementEditComponent } from './announcement-edit/announcement-edit.component';
import { VisitorEPassComponent } from './visitor-e-pass/visitor-e-pass.component';
import { ConciergeMessageListComponent } from './concierge-message-list/concierge-message-list.component';
import { PropertyManagerMessageListComponent } from './property-manager-message-list/property-manager-message-list.component';
import { PropertyManagerMessageComponent } from './property-manager-message/property-manager-message.component';
import { ConciergeMessageComponent } from './concierge-message/concierge-message.component';
import { ParceldeliveryComponent } from './parceldelivery/parceldelivery.component';
import { CondoByLawsComponent } from './condo-by-laws/condo-by-laws.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { LibrayComponent } from './libray/libray.component';
import { BusinessComponent } from './business/business.component';
import { ServiceApprovalComponent } from './service-approval/service-approval.component';
import { ServiceTermsAndConditionsComponent } from './service-terms-and-conditions/service-terms-and-conditions.component';
import { BudlingContactComponent } from './budling-contact/budling-contact.component';
import { AgendaService, DayService, MonthService, RecurrenceEditorAllModule, ScheduleModule, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { ServiceBoockedComponent } from './service-boocked/service-boocked.component';
import { EditServiceComponent } from './edit-service/edit-service.component';
import { MemoEditComponent } from './memo-edit/memo-edit.component';
import { TestComponent } from './test/test.component';

import { PayHeaderComponent } from './pay-header/pay-header.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PayComponent } from './pay/pay.component';
import { PayFooterComponent } from './pay-footer/pay-footer.component';
import { SubscriptioncheckComponent } from './subscriptioncheck/subscriptioncheck.component';
import { FlashDealsComponent } from './flash-deals/flash-deals.component';
import { LibrarylistComponent } from './librarylist/librarylist.component';
import { LibraryeditComponent } from './libraryedit/libraryedit.component';
import { FloorPlanListComponent } from './floor-plan-list/floor-plan-list.component';
import { FloorPlaneditComponent } from './floor-planedit/floor-planedit.component';
import { StaffDirectoryComponent } from './staff-directory/staff-directory.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ForgetPassowrdPageComponent } from './forget-passowrd-page/forget-passowrd-page.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';







@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AddswimmingpoolComponent,
    ResourcesComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    TermsAndConditionsComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    ProfileComponent,
    RegisterComponent,
    FeedbackComponent,
    ServicesComponent,
    ResourcesComponent,
    DiaryComponent,
    ResidentpostingComponent,
    CommentComponent,
    ResidentdirectoryComponent,
    ResidentDetailsComponent,
    ManageMemoComponent,
    MemoDetailsComponent,
    AddmemoComponent,
    SettingComponent,
    AnnouncementComponent,
    AnnouncementAddComponent,
    AnnouncementEditComponent,
    VisitorEPassComponent,
    ConciergeMessageListComponent,
    PropertyManagerMessageListComponent,
    PropertyManagerMessageComponent,
    ConciergeMessageComponent,
    ParceldeliveryComponent,
    CondoByLawsComponent,
    FloorPlanComponent,
    LibrayComponent,
    BusinessComponent,
    ServiceApprovalComponent,
    ServiceTermsAndConditionsComponent,
    BudlingContactComponent,
    ServiceBoockedComponent,
    EditServiceComponent,
    MemoEditComponent,
    TestComponent,
    PayComponent,
   

    PayHeaderComponent,
   

    PayFooterComponent,
   

    SubscriptioncheckComponent,
   

    FlashDealsComponent,
   

    LibrarylistComponent,
   

    LibraryeditComponent,
   

    FloorPlanListComponent,
   

    FloorPlaneditComponent,
   

    StaffDirectoryComponent,
    ForgetPassowrdPageComponent,
    StaffDetailsComponent,
    





  ],
  imports: [
  
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    FlatpickrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar:true,
      easing:'ease-in-out',
      easeTime:1000,
      progressAnimation:'increasing',
      maxOpened:1,
      autoDismiss:true,
    
     
      
    }),
    NgbModalModule,
    HttpClientModule,
    ScheduleModule, RecurrenceEditorAllModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    NgbModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgxStripeModule.forRoot('pk_test_51HqwWhIvNyzSTLksrxJLQTSrBtx6uc3TF8v8SfNh2j4cJiq6c5mBjxAWMYxruuZ9TSBh3DA9y3AkqNpL0NvaWmsw000w6mvNC5'),
    ReactiveFormsModule,
    FormsModule,



  ],
  exports: [ToastrModule,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [WebserviceService,NgxSpinnerService, {provide: LocationStrategy, useClass: HashLocationStrategy},DayService, WeekService, WorkWeekService, MonthService, AgendaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
