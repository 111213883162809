import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email: any = '';

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
  }

  onSend() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.email == '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email');
    } else {
      this.spinner.show();
      var params = {
        email: this.email
      }
      this.webservice.postData('sendmail', params).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.email = '';
          this.toastr.success(result.msg);
          this.router.navigateByUrl('/login');
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }
}
