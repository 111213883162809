import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WebserviceService } from "../api/webservice.service";
@Component({
  selector: "app-floor-planedit",
  templateUrl: "./floor-planedit.component.html",
  styleUrls: ["./floor-planedit.component.scss"],
})
export class FloorPlaneditComponent implements OnInit {
  loginData: any;
  edit_floor: any;
  created_by_id: any = "";
  created_by: any = "";
  property_id: any = "";
  property_name: any = "";
  title: any = "";
  short_descriptions: any = "";
  library_id: any = "";
  _id: any = "";
  pdf_file_type: any = "";

  type: any;
  subject: any = "";
  websitelink: any = "";
  pdf: any = "";
  file: any;
  floor_pdf: any = "";
  constructor(
    private router: Router,
    public webservice: WebserviceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.edit_floor = params["_id"];
      console.log("edit=", this.edit_floor);
    });
    this.loginData = JSON.parse(localStorage.getItem("LoginToken"));
    this.detail();
  }
  onChangeType(val) {
    console.log(val);
    this.type = val;
  }
  onFileChange(event) {
    if (!event.target.files.length) return;
    this.file = event.target.files[0];
    if (
     
      this.file.type == "application/pdf" 
      
    ) {
      this.pdf_file_type = event.target.files[0].type.split("/")[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        //console.log(e.target.result)
        this.floor_pdf = reader.result.toString();
      };
      reader.readAsDataURL(this.file);
    } else {
      this.toastr.error("Invalid Format. Please upload PDF.");
    }
  }
  detail() {
    this.spinner.show();
    this.webservice.getData("getFloorPlanById/" + this.edit_floor).then(
      (result: any) => {
        if (result.status == 1) {
          // this.created_by_id= result.data._id;
          // this.created_by= result.data.name;
          //  this. property_id= result.data.property_id;
          //   this.property_name= result.data.property_name;
          this.title = result.data.title;
          this.short_descriptions = result.data.short_descriptions;
          if(result.data.type=='1'){
            this.type= 'pdf'
            this.floor_pdf = result.data.floor_pdf[0].pdf;
          }else if(result.data.type=='2'){
            this.type= 'link'
            this.websitelink = result.data.floor_plan_link;
          }
          
          
          this._id = result.data._id;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }
  onUpdate() {
    console.log("file: ", this.file);
    if (this.type == "pdf") {
      if (this.title == "") {
        this.toastr.error("Enter floor plan subject");
      } else if (this.short_descriptions == "") {
        this.toastr.error("Enter floor plan description");
      } else if (this.floor_pdf == "") {
        this.toastr.error("Attach PDF file");
      } 
      // else if (this.pdf_file_type != "application") {
      //   console.log("file======", this.pdf_file_type);
      //   this.toastr.error("Please upload correct File Format");
      // } 
      else {
        this.spinner.show();
        let fd = new FormData();
        fd.append("created_by_id", this.loginData._id);
        fd.append("created_by", this.loginData.name);
        fd.append("property_id", this.loginData.property_id);
        fd.append("property_name", this.loginData.property_name);
        fd.append("title", this.title);
        fd.append("floor_pdf", this.file);
        fd.append("short_descriptions", this.short_descriptions);
        fd.append("floor_plan_id", this._id);
        console.log(fd);
        this.webservice.postData("updateFloorPlanPDF", fd).then(
          (result: any) => {
            if (result.status == 1) {
              this.toastr.success(result.msg);
            } else {
              this.toastr.error(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
    } else {
      if (this.title == "") {
        this.toastr.error("Please enter floor plan subject");
      } else if (this.short_descriptions == "") {
        this.toastr.error("enter floor plan description");
      } else if (this.websitelink == "") {
        this.toastr.error("Please enter floor plan link");
      } else {
        var params = {
          floor_plan_link: this.websitelink,
          created_by_id: this.loginData._id,
          created_by: this.loginData.name,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
          title: this.title,
          short_descriptions: this.short_descriptions,
          floor_plan_id: this._id,
        };
        {
          this.spinner.show();
          this.webservice.postData("updateFloorPlanLink", params).then(
            (result: any) => {
              if (result.status == 1) {
                this.toastr.success(result.msg);
              } else {
                this.toastr.error(result.msg);
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
            }
          );
        }
      }
    }
  }
}
