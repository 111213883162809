import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as Moment from 'moment';

import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventSettingsModel, View } from '@syncfusion/ej2-schedule';
import { ScheduleComponent } from '@syncfusion/ej2-angular-schedule';
@Component({
  selector: 'app-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  roomType: any;
  roomValue: any;
  loginData: any;
  list: any
  event: any;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  public setView: View = 'Month';
  public selectedDate: Date = new Date();
  public eventObject: EventSettingsModel = {};
  dataresize: boolean = false;
  @ViewChild("scheduleObj", { static: false })
  public scheduleObj: ScheduleComponent;

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { 
      this.refress();
    }
  

    ngOnInit() {
     this.refress();
      
    }

    async refress () {
    //   alert('233')
    // this.scheduleObj.refreshEvents();
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.roomType = JSON.parse(this.activatedRoute.snapshot.paramMap.get('type'));
    console.log(this.roomType);
    if(this.roomType == 'Party Room') {
      this.roomValue = 1;
    } else if (this.roomType == 'Swimming Pool'){
      this.roomValue = 3;
    } else if(this.roomType == 'Theatre Room') {
      this.roomValue = 2;
    } else if (this.roomType == 'Elevator') {
      this.roomValue = 4;
    } else if (this.roomType == 'Tennis Court') {
      this.roomValue = 5;
    } else if (this.roomType == 'Guest Room') {
      this.roomValue = 6;
    }
    console.log('roomValue: ',this.roomValue)
    this.spinner.show();
    this.webservice.getData('partyRoomList/' + this.loginData.property_id+'/'+this.roomValue).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        var events = [];
        for (var i = 0; i<this.list.length; i++){
          var sDateTime = this.list[i].formatted_start_date+' '+ this.list[i].start_time;
          var eDateTime = this.list[i].formatted_end_date_old+' '+ this.list[i].end_time;
          if(this.loginData._id == this.list[i].created_by_id) {
            var msg = this.list[i].message + ' booked by ' + this.list[i].created_by +', '+ this.loginData.suite_no
          } else {
            msg = this.list[i].message + ' booked'
          }
          events.push({
            Subject: msg,
            StartTime: new Date(sDateTime),
            EndTime: new Date(eDateTime),
            IsReadonly: true,
          })
        }
        this.eventObject  = {
          dataSource: events
       }; 
       console.log('worked: ',this.eventObject)
       this.scheduleObj.refreshEvents();
      } else {

      }
      this.spinner.hide();

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

    console.log('refresh is worked: ',this.eventObject)
  }







  onAdd() {
    this.router.navigate(['add-service',JSON.stringify(this.roomValue)]);
  }


  //calender


}
