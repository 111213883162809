import { Component, OnInit, ViewChild } from '@angular/core';
import { EventSettingsModel, PopupOpenEventArgs, View } from '@syncfusion/ej2-schedule';
import { EJ2Instance, ScheduleComponent } from '@syncfusion/ej2-angular-schedule';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebserviceService } from '../api/webservice.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { Button } from '@syncfusion/ej2-buttons';

@Component({
  selector: 'app-service-boocked',
  templateUrl: './service-boocked.component.html',
  styleUrls: ['./service-boocked.component.scss']
})
export class ServiceBoockedComponent implements OnInit {
  loginData: any = [];
  public setView: View = 'Month';
  public selectedDate: Date = new Date();
  public eventObject: EventSettingsModel = {};
  dataresize: boolean = false;
  @ViewChild("scheduleObj", { static: true })
  public scheduleObj: ScheduleComponent;

  roomType: any = '';
  roomValue: any = '';
  deleteId:any = '';

  
  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    public webservice: WebserviceService,
    private toastr: ToastrService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.roomType = JSON.parse(this.activatedRoute.snapshot.paramMap.get('type'));
    console.log(this.roomType);
    if(this.roomType == 'Party Room') {
      this.roomValue = 1;
    } else if (this.roomType == 'Swimming Pool'){
      this.roomValue = 3;
    } else if(this.roomType == 'Theatre Room') {
      this.roomValue = 2;
    } else if(this.roomType == 'Elevator') {
      this.roomValue = 4;
    } else if(this.roomType == 'Tennis Court') {
      this.roomValue = 5;
    } else if (this.roomType == 'Guest Room') {
      this.roomValue = 6;
    }
    this.spinner.show();
    this.webservice.getData('partyRoomList/' + this.loginData.property_id+'/'+this.roomValue).then((result: any) => {

      if (result.status == 1) {
        var events = [];
        for (var i = 0; i<result.data.length; i++){
          var sDateTime = result.data[i].formatted_start_date+' '+ moment(result.data[i].start_time, "h:mm:ss A").format("HH:mm")
          var eDateTime = result.data[i].formatted_end_date_old+' '+ moment(result.data[i].end_time, "h:mm:ss A").format("HH:mm")
          if(this.loginData._id == result.data[i].created_person_id) {
            var msg = this.loginData.suite_no
          } else {
            msg = ' booked'
          }
          events.push({
            Subject: msg,
            StartTime: new Date(sDateTime),
            EndTime: new Date(eDateTime),
            Id: result.data[i]._id,
            IsReadonly: false,
          })
        }
        this.eventObject  = {
          dataSource: events
       }; 
       this.scheduleObj.refreshEvents();
      } else {

      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  // onrefresh() {
  //   this.scheduleObj.refreshEvents();
  //   console.log(this.scheduleObj.eventsData)
  // }

  onAdd() {
    this.router.navigate(['home/add-service',JSON.stringify(this.roomValue)]);
  }

  onPopupOpen(args: PopupOpenEventArgs): void {
    console.log('args: ',args)
    console.log('current: ',new Date())
    var currentDate = new Date(); // Get current date
    var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-edit" : ".e-schedule-dialog .e-event-edit";
    var buttonDelElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
    
    if (args && args.target && (args.type === 'QuickInfo' || args.type === 'Editor')) {
      let isEmptyCell = args.target.classList.contains('e-work-cells') || args.target.classList.contains('e-header-cells');
      if (isEmptyCell) {
          args.cancel = true;
      }
  } else {
      console.error('Invalid event arguments or missing target:', args);
  }
    var editButton = document.querySelector(buttonElement);
    var delButton = document.querySelector(buttonDelElement);
    

    if(currentDate > args.data.EndTime ){
      if (editButton && (editButton as EJ2Instance).ej2_instances) {
        ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }
  
      if (delButton && (delButton as EJ2Instance).ej2_instances) {
        ((delButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }
      if (args.type == "DeleteAlert") {
        this.deleteId = args.data.Id;
        console.log(this.deleteId)
      }
    }
    else{
      if (editButton && (editButton as EJ2Instance).ej2_instances) {
        ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }
  
      if (delButton && (delButton as EJ2Instance).ej2_instances) {
        ((delButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }

      if (args.type == "DeleteAlert") {
        this.deleteId = args.data.Id;
        console.log(this.deleteId)
      }
      if( args.type == "Editor" && args.data.Id != undefined) {
        console.log('edit')
        let navihationExtras: NavigationExtras = {
          queryParams: {
            bookingId: args.data.Id,
            Type: this.roomValue
          }
        }
        this.router.navigate(['home/edit-service'], navihationExtras);
        this.ngOnInit();
      }
    }
  }

  // onPopupOpen_(args: PopupOpenEventArgs): void {
  //   console.log('args: ',args)
  //     if (args.type == "DeleteAlert") {
  //       this.deleteId = args.data.Id;
  //       console.log(this.deleteId)
  //     } 
      
  //     // console.log(args.type);

  //     if( args.type == "Editor" && args.data.Id != undefined) {
  //       console.log('edit')
  //       let navihationExtras: NavigationExtras = {
  //         queryParams: {
  //           bookingId: args.data.Id,
  //           Type: this.roomValue
  //         }
  //       }
  //       this.router.navigate(['home/edit-service'], navihationExtras);
  //       this.ngOnInit();
  //     }
  //     if (args.type == 'QuickInfo'  && args.data.Id == undefined) {
  //       this.ngOnInit();
  //     }
    
  // }
  oneventRendered(event) {
    console.log('oneventRendered: ',event)
    if(this.deleteId != "") {
      console.log(this.deleteId)
      // Delete functionlity
      var params = {
        booking_id: this.deleteId
      }
      this.webservice.postData('deleteBooking', params).then((result: any) => {
        if (result.status == 1) {
          this.ngOnInit();
          this.deleteId = '';
          this.toastr.success(result.msg);
        } else {
          this.deleteId = '';
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }

  }

}
