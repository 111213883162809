import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
@Component({
  selector: 'app-memo-edit',
  templateUrl: './memo-edit.component.html',
  styleUrls: ['./memo-edit.component.scss']
})
export class MemoEditComponent implements OnInit {
  subject: any = '';
  sDate: any = '';
  sTime: any = '';
  eDate: any = '';
  eTime: any = '';
  message: any = '';
  loginData: any;
  file:any;
  pdf: any = "";
  memoId: any = '';
  pdf_file_type:any;
  currentDate:any =new Date();


  constructor(public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.memoId = params.bookingId
      console.log('memoId: ',this.memoId);
      this.spinner.show();
      this.webservice.getData('getMemoDetails/'+this.memoId).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.subject = result.data.subject;
          this.sDate = result.data.formatted_date;
          this.sTime = moment(result.data.time, "h:mm:ss A").format("HH:mm");
          this.eDate = result.data.formatted_end_date;
          this.eTime = moment(result.data.end_time, "h:mm:ss A").format("HH:mm");;
          this.message = result.data.message;
          this.pdf = result.data.memo_doc;

        } else {

        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    })
  }

  OnDeletePDF() {
    this.pdf = '';
  }

  editMemo() {
    console.log('file: ', this.file);
    if (this.subject == '') {
        this.toastr.error('Enter subject');
    } else if (this.sDate == '') {
        this.toastr.error('Enter start date');
    } else if (this.sTime == '') {
        this.toastr.error('Enter start time');
    } else if (this.eDate == '') {
        this.toastr.error('Enter end date');
    } else if (this.eTime == '') {
        this.toastr.error('Enter end time');
    } else if (this.message == '') {
        this.toastr.error('Enter message');
    } else if (this.sDate > this.eDate) {
      this.toastr.error("Enter correct end date");
    } else {
        var StartDate = moment(this.sDate).format('LL');
        var endDate = moment(this.eDate).format('LL');
        var startTime = moment(new Date(StartDate + ' ' + this.sTime)).format('LT');
        var endTime = moment(new Date(endDate + ' ' + this.eTime)).format('LT');
        
        var sDateTime = moment(StartDate + ' ' + this.sTime);
        var eDateTime = moment(endDate + ' ' + this.eTime);
  
        var currentTime = moment();
  
        if (eDateTime.isBefore(sDateTime)) {
            this.toastr.error('End time cannot be earlier than start time');
        } else if (sDateTime.isBefore(currentTime)) {
            this.toastr.error('Start time cannot be in the past');
        } else if (sDateTime.isSame(eDateTime)) {
            this.toastr.error('End time cannot be equal to start time');
        } else {
            if (this.file != undefined) {
                this.spinner.show();
                let fd = new FormData();
                fd.append('memo_id', this.memoId);
                fd.append('date_required', StartDate);
                fd.append('end_date', endDate);
                fd.append('time', startTime);
                fd.append('end_time', endTime);
                fd.append('message', this.message);
                fd.append('subject', this.subject);
                fd.append('memo_doc', this.file);
                console.log(fd);
                this.webservice.postData('updateMemoWithImage', fd).then((result: any) => {
                    if (result.status == 1) {
                        this.toastr.success(result.msg);
                    } else {
                        this.toastr.error(result.msg);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    console.log(err);
                });
            } else {
                var params = {
                    memo_id: this.memoId,
                    date_required: StartDate,
                    end_date: endDate,
                    time: startTime,
                    end_time: endTime,
                    subject: this.subject,
                    message: this.message,
                }
                this.webservice.postData('updateMemoWithoutImage', params).then((result: any) => {
                    if (result.status == 1) {
                        this.toastr.success(result.msg);
                    } else {
                        this.toastr.error(result.msg);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    console.log(err);
                });
  
            }
        }
    }
  }
  


//   editMemo() {
//     console.log('file: ',this.file)
//     if (this.subject == '') {
//       this.toastr.error('Enter subject');
//     } else if (this.sDate == '') {
//       this.toastr.error('Enter start date');
//     } else if (this.sTime == '') {
//       this.toastr.error('Enter start time');
//     } else if (this.eDate == '') {
//       this.toastr.error('Enter end date');
//     } else if (this.sDate > this.eDate) {
//       this.toastr.error('Enter Correct end date');
//     }else if (this.eTime == '') {
//       this.toastr.error('Enter end time');
//     } else if (this.message == '') {
//       this.toastr.error('Enter message');
//     } 
//     // else if(this.pdf == '') {
//     //   this.toastr.error('Attach PDF file');
//     // }
//     //  else if ( this.pdf_file_type != "application"){
//     //   console.log('file======',this.pdf_file_type)
//     //      this.toastr.error('Please upload correct File Format');
//     //    }
       
//        else {
//     var StartDate = moment(this.sDate).format('LL');
//     var endDate = moment(this.eDate).format('LL');
//     var startTime = moment(new Date(StartDate + ' ' + this.sTime)).format('LT')
//     var endTime = moment(new Date(endDate + ' ' + this.eTime)).format('LT')

//  if(this.file != undefined ) {
//     this.spinner.show();
//     let fd=new FormData();
//     fd.append('memo_id',this.memoId);
//     fd.append('date_required',StartDate);
//     fd.append('end_date',endDate);
//     fd.append('time',startTime);
//     fd.append('end_time',endTime);
//     fd.append('message',this.message);
//     fd.append('subject',this.subject);
//     fd.append('memo_doc',this.file);
//     console.log(fd);
//     this.webservice.postData('updateMemoWithImage', fd).then((result: any) => {
//       if (result.status == 1) {
//         this.toastr.success(result.msg);
//       } else {
//         this.toastr.error(result.msg);
//       }
//       this.spinner.hide();
//     }, (err) => {
//       this.spinner.hide();
//       console.log(err);
//     });
//   } else {
//     var params = {
//       memo_id: this.memoId,
//           date_required:StartDate,
//           end_date: endDate,
//           time: startTime,
//           end_time: endTime,
//           subject: this.subject,
//           message: this.message,
//     }
//     this.webservice.postData('updateMemoWithoutImage', params).then((result: any) => {
//       if (result.status == 1) {
//         this.toastr.success(result.msg);
//       } else {
//         this.toastr.error(result.msg);
//       }
//       this.spinner.hide();
//     }, (err) => {
//       this.spinner.hide();
//       console.log(err);
//     });

//   }

//     }
//   }


  onFileChange(event) {
    console.log(event)
    if(!event.target.files.length) return;
  this.file = event.target.files[0];
  if (this.file.type == "application/pdf") {
    this.pdf_file_type= event.target.files[0].type.split('/')[0]
  console.log('file',this.file)

  const reader = new FileReader();
  reader.onload = e =>{
    //console.log(e.target.result)
    this.pdf = reader.result.toString();
    console.log('pdf',this.pdf)
  };
  reader.readAsDataURL(this.file);
  }else{
    this.toastr.error("Invalid format. Please upload PDF.")
  }

  }
  checkpdf(pdf){
    
    var pdf_type= pdf.substring(pdf.lastIndexOf('/') + 1);
    console.log(pdf_type)
    if( pdf_type== 'undefined'){
      console.log('false============')
      return false
    }else if( pdf_type !='undefined'){
      console.log('true============')
      return true
    }
  }
}
