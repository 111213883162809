import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-residentposting',
  templateUrl: './residentposting.component.html',
  styleUrls: ['./residentposting.component.css']
})
export class ResidentpostingComponent implements OnInit {
  loginData: any;
  list: any = [];
  noDatafound: boolean = false;
  cancelClicked

  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('residentServiceList/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        this.noDatafound = false;
      } else {
        this.noDatafound = true;
        // this.webservice.presentToast(this.data.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.noDatafound = true;
      this.spinner.hide();
      console.log(err);
    });
  }


  onDelete(id) {
    console.log(id);

    var params = {
      resient_service_id: id,
    }
    this.webservice.postData('residentServiceDelete', params).then((result: any) => {
      if(result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }
  onComment(item) {
    this.router.navigate(['home/comment',JSON.stringify(item)]);
  }

}
