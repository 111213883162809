import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-announcement-add',
  templateUrl: './announcement-add.component.html',
  styleUrls: ['./announcement-add.component.scss']
})
export class AnnouncementAddComponent implements OnInit {
  loginData: any;
  title: any = '';
  description: any = '';

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  }

  onAdd() {
    if (this.title == '') {
      this.toastr.error('Please enter title');
    } else if (this.description == '') {
      this.toastr.error('Please enter description');
    } else {
      var params = {
        created_by_id: this.loginData._id,
        created_by: this.loginData.name,
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        title: this.title,
        description: this.description
      }
      this.spinner.show();
      this.webservice.postData('saveAnnouncement', params).then((result: any) => {
        if (result.status == 1) {
          this.title = '';
          this.description = '';
          // this.webservice.announcementCountSignal.next(true);
          this.toastr.success(result.msg);
          this.router.navigate(['/home/announcement']);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }
  }

}
