import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as Moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-parceldelivery',
  templateUrl: './parceldelivery.component.html',
  styleUrls: ['./parceldelivery.component.css']
})
export class ParceldeliveryComponent implements OnInit {
  loginData: any = [];
  search: any = '';
  list: any = [];
  currentDate: any;
  cancelClicked
  noDataFound: boolean = false;

  residentId: any = '';
  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router) { }

  ngOnInit(): void {
    let date = new Date();
    this.currentDate = Moment(date).format('YYYY-MM-DD');
    console.log('currentDate: ',this.currentDate);
    // this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.activatedRoute.queryParams.subscribe(params => {
      this.residentId = params.id;
      console.log(this.residentId)
    })

    this.spinner.show();
    this.webservice.getData('getResidentsForParcel/'+this.residentId).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        console.log(this.list);
        this.noDataFound = false;
      } else {
        this.noDataFound = true;
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.noDataFound = true;
      console.log(err);
    });
  }

 

  onItemDecrement(count, id) {
      console.log(count, id)
      if (count > 0) {
        count--
        var params = {
          sender_id: this.loginData._id,
          user_id: id,
          deleted_status: 'delete'
        }
        this.webservice.postData('sendParcelMsg', params).then((result: any) => {
          if (result.status == 1) {
            this.ngOnInit()
          } else {
            this.toastr.error(result.msg);
          }

        }, (err) => {
          console.log(err);
        });

      } else {
        this.toastr.error('Please add parcel')
      }

  }

  onItemIncrement(count, id) {
      console.log(count, id)
      count++
      var params = {
        sender_id: this.loginData._id,
        user_id: id,
        deleted_status: 'save'
      }
      this.webservice.postData('sendParcelMsg', params).then((result: any) => {
        if (result.status == 1) {
          this.ngOnInit()
        } else {
          this.toastr.error(result.msg);
        }

      }, (err) => {
        console.log(err);
      });

  }

  onRecive(id, date) {
    var params = {
      sender_id: this.loginData._id,
      user_id: id,
      received_date: date
    }
    this.webservice.postData('parcelClaimed', params).then((result: any) => {
      if (result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);

      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onDelete(id, date) {
    var params = {
      user_id: id,
      received_date: date
    }
    this.webservice.postData('deleteParcel', params).then((result: any) => {
      if(result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
