import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-concierge-message-list',
  templateUrl: './concierge-message-list.component.html',
  styleUrls: ['./concierge-message-list.component.scss']
})
export class ConciergeMessageListComponent implements OnInit {
  list: any;
  loginData: any;
  search: any = '';
  noDatafound: boolean = true;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getResidentByProperty/' + this.loginData.property_id+'/undefined/'+this.loginData._id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        this.noDatafound = true;
        console.log("content: ", this.list);
      } else {
        this.noDatafound = false;
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.noDatafound = false;
      this.spinner.hide();
      console.log(err);
    });

  }

  onSearch(val) {
    if (val != '') {
      this.webservice.getData('getResidentByProperty/' + this.loginData.property_id+'/'+val+'/'+this.loginData._id).then((result: any) => {
        if(result.status == 1) {
          this.list = result.data;
          this.noDatafound = true;
        } else {
          console.log(result.msg);
          this.noDatafound = false;
        }

      }, (err) => {
        this.noDatafound = false;
        console.log(err);
      });

    } else {
      this.ngOnInit();
    }
  }

  chatdetails_Page(name, id) {
    let navihationExtras: NavigationExtras = {
      queryParams: {
        reciverId: id,
        nickname: name
      }
    }
    this.router.navigate(['home/concierge-message'], navihationExtras)
  }

}
