import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  oldPassword: any = '';
  newPassword: any = '';
  cPassword: any = '';
 
  loginData: any;

  visible:boolean = true;
  changetype:boolean =true;
  visible1:boolean = true;
  changetype1:boolean =true;
  visible2:boolean = true;
  changetype2:boolean =true;
  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  }
  
  onChangePassword() {
    var pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@$!%*?&]{8,})$/;
  
    if(this.oldPassword == '') {
      this.toastr.error('Please enter current password');
    }  else if (this.newPassword == '') {
      this.toastr.error('Please enter new password');
    } else if (!pass.test(this.newPassword)){
      this.toastr.error('Passwords must be atleast 8 characters long with a combination of uppercase and lowercase letters and atleast one number');
    } else if (this.cPassword == '') {
      this.toastr.error('Please enter confirm password');
    } else if (this.cPassword.length < 6) {
      this.toastr.error('Passwords must be atleast 8 characters long with a combination of uppercase and lowercase letters and atleast one number');
    } else if (this.newPassword != this.cPassword) {
      this.toastr.error('Confirm password does not match');
    } else {
      var params = {
        user_id: this.loginData._id,
        old_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_password: this.cPassword
      }
      this.spinner.show();
      this.webservice.postData('changePassword', params).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
          this.oldPassword = '';
          this.newPassword = '';
          this.cPassword = '';
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    }

  }
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  viewpass1(){
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1;
  }
  viewpass2(){
    this.visible2 = !this.visible2;
    this.changetype2 = !this.changetype2;
  }


}
