import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from './api/webservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'eq11';
  loginData: any;
  paymentstaus:boolean;
  UserData: any;
  // propertymanagerid: any = '';
  constructor(private router: Router,public webservice: WebserviceService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) {
    // this.router.navigateByUrl('/home')
  }

  ngOnInit(): void {
    if(localStorage.getItem('LoginToken')){
      this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
      // this.propertymanagerid = this.loginData._id
      console.log('loginData: ',this.loginData);
      if(this.loginData == null || this.loginData == '' || this.loginData == undefined) {
        console.log('loginData: ',this.loginData);
        this.router.navigateByUrl('/login');
      } else {
        // this.checkpaymentstatus();
        this.router.navigateByUrl('/home');

      }
    }
    // this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    
    

  }
  // checkpaymentstatus(){
    
  //   this.webservice.getData('chkParopertyManagerPayment/' + this.loginData._id).then((response:any)=>{
  //     if(response['success'] == 0){
  //       this.paymentstaus = false;
  //       console.log('no payment',this.paymentstaus)
  //       this.toastr.error(response.message);
  //       this.router.navigateByUrl('/pay');
        
  
  //     }else{
  //       this.paymentstaus = true;
  //       console.log('payment done',this.paymentstaus)
  //       this.toastr.success("Login Successfull");
  //       this.router.navigateByUrl('/home');
  //     }
  //   })
  // }
}
