import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService {
  BaseUrl = environment.url+'webservice/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization':'',
    })
  };

  constructor(public http: HttpClient) { }

  getData(url) {
    return new Promise((resolve, reject) => {
      this.http.get(this.BaseUrl + url).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });

  }

  postData(url, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.BaseUrl + url, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  getcountry(){
    return this.http.get("https://ip-api.com/json");
  }
}
// /var/www/html/nodeserver.brainiuminfotech.com/public_html/projects/pranay/samim/eq11

// https://nodeserver.mydevfactory.com/projects/pranay/samim/eq11/#/