import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  memo: boolean = false;
  Property_manager_message: boolean = false;
  Concierge_Message: boolean = false;
  Parcel_Delivery: boolean = false;
  Visitor_ePass: boolean = false;
  Family_Member: boolean = false;
  Flash_Deals: boolean = false;
  Resources: boolean = false;
  Announcement: boolean = false;
  Tracking: boolean = false;
  Resident_Posting: boolean = false;
  Swimming_Pool: boolean = false;
  Party_Room: boolean = false;
  Theatre_Room: boolean = false;
  Resident_Directory: boolean = false;
  Service_Approval: boolean = false;
  Tennis_court: boolean = false;
  Elevator: boolean = false;
  Guest_Room: boolean = false;
  staff_directory:boolean = false;
  loginData: any;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getMenu/'+ this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        console.log(result.data);
        this.memo = result.data.memo;
        this.Property_manager_message = result.data.Property_manager_message;
        this.Concierge_Message = result.data.Concierge_Message;
        this.Parcel_Delivery = result.data.Parcel_Delivery;
        this.Visitor_ePass = result.data.Visitor_ePass;
        this.Family_Member = result.data.Family_Member;
        this.Flash_Deals = result.data.Flash_Deals;
        this.Resources = result.data.Resources;
        this.Announcement = result.data.Announcement;
        this.Tracking = result.data.Tracking;
        this.Resident_Posting = result.data.Resident_Posting;
        this.Swimming_Pool = result.data.Swimming_Pool;
        this.Party_Room = result.data.Party_Room;
        this.Theatre_Room = result.data.Theatre_Room;
        this.Resident_Directory = result.data.Resident_Directory;
        this.staff_directory = result.data.staff_directory;
        this.Service_Approval = result.data.Service_Approval;
        this.Tennis_court = result.data.Tennis_court;
        this.Elevator = result.data.Elevator;
        this.Guest_Room = result.data.Guest_Room;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  onSave() {
    var params = {
      memo: this.memo,
      Property_manager_message: this.Property_manager_message,
      Concierge_Message: this.Concierge_Message,
      Parcel_Delivery: this.Parcel_Delivery,
      Visitor_ePass: this.Visitor_ePass,
      Family_Member: this.Family_Member,
      Flash_Deals: this.Flash_Deals,
      Resources: this.Resources,
      Announcement: this.Announcement,
      Tracking: this.Tracking,
      Resident_Posting: this.Resident_Posting,
      Swimming_Pool: this.Swimming_Pool,
      Party_Room: this.Party_Room,
      Theatre_Room: this.Theatre_Room,
      created_by_id: this.loginData._id,
      created_by: this.loginData.name,
      property_id: this.loginData.property_id,
      property_name: this.loginData.property_name,
      Resident_Directory: this.Resident_Directory,
      Service_Approval: this.Service_Approval,
      Tennis_court: this.Tennis_court,
      Elevator: this.Elevator,
      Guest_Room: this.Guest_Room,
      staff_directory:this.staff_directory
    }
    console.log(params);
    this.spinner.show();
    this.webservice.postData('saveMenu', params).then((result: any) => {
      if(result.status ==1) {
        this.toastr.success(result.msg);
        this.router.navigate(['/home']);
      } else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  selectAll(checked: boolean) {
     this.memo = checked;
   this.Property_manager_message = checked;
    this.Concierge_Message = checked;
    this.Parcel_Delivery = checked;
     this.Visitor_ePass = checked;
     this.Family_Member = checked;
     this.Flash_Deals = checked;
     this.Resources = checked;
    this.Announcement = checked;
    this.Tracking = checked;
     this.Resident_Posting = checked;
     this.Swimming_Pool = checked;
     this.Party_Room = checked;
     this.Theatre_Room = checked;
     this.Resident_Directory= checked;
     this.Service_Approval = checked;
     this.Tennis_court= checked;
    this.Elevator= checked;
    this.Guest_Room= checked;
    this.staff_directory= checked;
  }

}
