import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
declare var intlTelInput: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  fName: any = '';
  lName: any = '';
  bDate: any = '';
  bMonth: any = '';
  bYear: any = '';
  gender: any = '';
  email
  cellNumber;
  country: any = '';
  state: any = '';
  city: any = '';
  suiteNo: any = '';
  address: any = '';
  postalCode: any = '';
  eName
  eEmail: any = '';
  eCellNo
  eReletation: any = '';
  birthDate = [{ date: '1' }, { date: '2' }, { date: '3' }, { date: '4' }, { date: '5' }, { date: '6' }, { date: '7' }, { date: '8' }, { date: '9' }, { date: '10' }, { date: '11' }, { date: '12' }, { date: '13' }, { date: '14' }, { date: '15' }, { date: '16' }, { date: '17' }, { date: '18' }, { date: '19' }, { date: '20' }, { date: '1' }, { date: '21' }, { date: '22' }, { date: '23' }, { date: '24' }, { date: '25' }, { date: '26' }, { date: '27' }, { date: '28' }, { date: '29' }, { date: '30' }, { date: '31' }];
  birthMonth = [{ month: 'Jan' }, { month: 'Feb' }, { month: 'Mar' }, { month: 'Apr' }, { month: 'May' }, { month: 'Jun' }, { month: 'Jul' }, { month: 'Aug' }, { month: 'Sep' }, { month: 'Oct' }, { month: 'Nov' }, { month: 'Dec' },];
  birthYear: any = [];
  countryList: any = [];
  stateList: any = [];
  cityList: any = [];
  loginData: any;
  userType: any;
  consoNameList: any = [];
  condoName: any = '';
  homeNo: any = '';
  property_management_company:any='';

  cellNumberValidation: boolean = false;
  homenoValidation: boolean = false;
  eCellNoValidation: boolean = false;
 
  adult_status: any ='';
  country_code:any='';
  homeCountryCode:any='';
  contactCountryCode:any ='';

  phone: any;
  whatsapp: any;
  emergency: any;
  shortcountrycode:any;





  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { 
      setTimeout(() => {
        this.getcountry();
      },500);
    }

  ngOnInit(): void {
    for (var i = (new Date()).getFullYear() - 100; i <= (new Date()).getFullYear(); i++) {
      this.birthYear.push({ year: i });
    }
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('userProfile/' + this.loginData._id).then((result: any) => {
      if (result.status == 1) {
        console.log(result);
        this.userType = result.data.user_type;
        this.fName = result.data.name.split(' ').slice(0, -1).join(' ');
        this.lName = result.data.name.split(' ').slice(-1).join(' ');
        this.email = result.data.email;
        this.cellNumber = result.data.contact_no;
        // this.streetNumber = val.street_no;
        this.suiteNo = result.data.suite_no;
        // this.streetName = result.data.street_name;
        this.postalCode = result.data.postal_code;
        this.eEmail = result.data.emergency_contact_email;
        this.eName = result.data.emergency_contact_name;
        this.eCellNo = result.data.emergency_contact_phone_no;
        this.eReletation = result.data.emergency_contact_relationship,
          // this.residentType = result.data.resident_type;
          this.gender = result.data.gender;
        this.address = result.data.address;
        this.country = result.data.country_id;
        this.state = result.data.state_id;
        this.city = result.data.city_id;
        this.bDate = result.data.b_date;
        this.bMonth = result.data.birth_month;
        this.bYear = result.data.b_year;
        this.condoName = result.data.property_id;
        this.homeNo = result.data.home_no; 
        this.country_code = result.data.country_code;
        this.homeCountryCode = result.data.homeCountryCode;
        this.adult_status = result.data.adult_status;
        this.property_management_company= result.data.property_management_company;
        this.contactCountryCode = result.data.contactCountryCode;

        this.phone.setNumber(result.data.country_code+result.data.contact_no);
        this.whatsapp.setNumber(result.data.homeCountryCode+result.data.home_no);
        this.emergency.setNumber(result.data.contactCountryCode+result.data.emergency_contact_phone_no);

        this.allCountryList();
        this.allStateList();
        this.allCityList();
        this.allProperty();

      } else {
        console.log('error: ', result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
    setTimeout(() => {
      this.phone = intlTelInput(document.querySelector("#Phone"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
     
      this.whatsapp = intlTelInput(document.querySelector("#whatsapp"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
      this.emergency = intlTelInput(document.querySelector("#emergency"), {
        initialCountry: this.shortcountrycode,
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
      });
  
      

    },100);
    



}
getcountry(){
  this.webservice.getcountry().subscribe((res:any)=>{  
    //this.ipAddress=res.ip;  
    var short_code= res.countryCode
    this.shortcountrycode = (short_code).toLowerCase();
    console.log('country===========',this.shortcountrycode)
  }); 
}
  onChangeDate(val) {
    this.bDate = val;
  }
  onChangeMonth(val) {
    this.bMonth = val;
  }

  onChangeGender(val) {
    this.gender = val;
  }
  onChangeYear(val) {
    this.bYear = val;
  }

  onChangeReletation(val) {

    console.log(val)
    if (val == 'friend') {
      this.eReletation = 'friend';
    } else {
      this.eReletation = 'family';
    }
  }

  allCountryList() {
    this.webservice.getData('countryList').then((result: any) => {
      if (result.status == 1) {
        this.countryList = result.data;
        console.log('countryList: ', this.countryList);
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });

  }


  allStateList() {
    this.webservice.getData('stateList/' + this.country).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stateList = result.data;
        console.log('stateList: ', this.stateList)
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      console.log(err);
    });
  }

  allCityList() {
    this.webservice.getData('cityList/' + this.state).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.cityList = result.data;
        console.log('cityList: ', this.cityList)
      } else {
        console.log(result.msg)
      }

    }, (err) => {
      console.log(err);
    });

  }
  allProperty(){
    if (this.city != '') {
      this.webservice.getData('propertyListByCity/' + this.city).then((result: any) => {
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  OnChangeCountry(val) {
    this.state = ''; // Reset state
    this.city = ''; // Reset city
    this.stateList = []; // Reset state list
    this.cityList = []; // Reset city list
    this.consoNameList = []; // Reset consoName list
    this.country = val;
    console.log(this.country)
    if (this.country != '') {
      this.spinner.show();
      this.webservice.getData('stateList/' + this.country).then((result: any) => {
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  OnChangeState(val) {
    this.city = ''; // Reset city
  this.cityList = []; // Reset city list
  this.consoNameList = []; // Reset consoName list
    this.state = val;
    console.log(this.state)
    if (this.state != '') {
      this.webservice.getData('cityList/' + this.state).then((result: any) => {
        if (result.status == 1) {
          this.cityList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  onChangeCity(val) {
    this.consoNameList = []; // Reset consoName list
    this.city = val;
    if (this.city != '') {
      this.webservice.getData('propertyListByCity/' + this.country+'/' +this.state + '/'+ this.city).then((result: any) => {
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }

  onAdult(val){
    this.adult_status = val;
  }

  onUpdateProfile() {
    var selectedCountryData = this.phone.getNumber();
    // var dialCode = selectedCountryData.dialCode;
     var phone = this.phone.getSelectedCountryData().dialCode;
     var codeLength= phone.length+1;
       console.log(selectedCountryData);
     console.log(phone)
     console.log('******',)
     this.cellNumber = selectedCountryData.substring(codeLength);
   console.log('******',this.cellNumber)
   /////////////////////////////////
   var selectedCountryData1 = this.whatsapp.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var whatsapp = this.whatsapp.getSelectedCountryData().dialCode;
    var codeLength= whatsapp.length+1;
      console.log(selectedCountryData1);
    console.log(whatsapp)
    console.log('******',)
    this.homeNo = selectedCountryData1.substring(codeLength);
  console.log('******',this.homeNo)
  //////////////////////////////////
  var selectedCountryData2 = this.emergency.getNumber();
  // var dialCode = selectedCountryData.dialCode;
   var emergency = this.emergency.getSelectedCountryData().dialCode;
   var codeLength= emergency.length+1;
     console.log(selectedCountryData2);
   console.log(emergency)
   console.log('******',)
   this.eCellNo = selectedCountryData2.substring(codeLength);
 console.log('******',this.eCellNo)





    var params = {
      user_id: this.loginData._id,
      name: this.fName + ' ' + this.lName,
      birthday: this.bMonth + '-' + this.bDate + '-' + this.bYear,
      gender: this.gender,
      email: this.email,
      contact_no: this.cellNumber,
      suite_no: this.suiteNo,
      country_id: this.country,
      state_id: this.state,
      city_id: this.city,
      address: this.address,
      postal_code: this.postalCode,
      emergency_contact_name: this.eName,
      emergency_contact_email: this.eEmail,
      emergency_contact_phone_no: this.eCellNo,
      emergency_contact_relationship: this.eReletation,
      birth_month: this.bMonth,
      property_id: this.condoName,
      resident_type: '',
      home_no: this.homeNo,
      adult_status: this.adult_status,
      // contactCountryCode:this.contactCountryCode,
      property_management_company: this.property_management_company,

      country_code : '+' + phone,        
      homeCountryCode: '+' + whatsapp,
      contactCountryCode: '+' + emergency,
    }
    console.log(params)
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    if (this.fName == '') {
      this.toastr.error('Please enter property manager first name');
    }
     else if (this.lName == '') {
      this.toastr.error('Please enter property manager last name');
    }

    else if (this.country == '') {
      this.toastr.error('Please select country');
    } 
    else if (this.state == '') {
      this.toastr.error('Please select state');
    } 
    else if (this.city == '') {
      this.toastr.error('Please select city');
    } 
    else if (this.condoName == '') {
      this.toastr.error('Please select property name');
    } 
    else if (this.address == '') {
      this.toastr.error('Please enter address');
    } 
    // else if (this.postalCode == '') {
    //   this.toastr.error('Please enter postal code');
    // }
    else if (this.cellNumber == '') {
      this.toastr.error('Please enter phone number');
    } 
    else if (!rem.test(this.cellNumber)) {
      this.toastr.error('Phone number should contain 10 digits. Please enter valid phone number. ');
    }
    else if (this.homeNo!= '' && !rem.test(this.homeNo)) {
     
      this.toastr.error('Whatsapp number should contain 10 digits. Please enter valid number. ');
    }

    //  else if (this.bDate == '' || this.bMonth == '' || this.bYear == '') {
    //   this.toastr.error('Please select birthday');
    // } 
    // else if (this.gender == '') {
    //   this.toastr.error('Please select gender');
    // } 
    else if (this.email == '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email')
    } 
    
   
   
    
    // else if (this.suiteNo == '') {
    //   this.toastr.error('Please enter suite number');
    // }
    //   else if (this.subAmount == '') {
    //   this.toastr.error('Please enter subscription ammount');
    // } 
    // else if (this.subDate == '') {
    //   this.toastr.error('Please enter subscription date');
    // } 
    // else if (this.propertyManagementCompany == '') {
    //   this.toastr.error('Please enter Property Management Company');
    // }

    
    else if (this.eCellNo!= '' && !rem.test(this.eCellNo)){
    
        this.toastr.error('Emergency number should contain 10 digits. Please enter valid phone number. ');
      }
      else if (this.eEmail !== '' && !re.test(this.eEmail)){
        this.toastr.error('Enter valid emergency email. ');
      
        
    }
     
    
    else {
      this.spinner.show();
      this.webservice.postData('updateProfile', params).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
          localStorage.setItem('LoginToken', JSON.stringify(result.data));
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

}
