import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  item: any;
  list: any = [];
  noData: boolean = false;
  loginData: any;
  comment: any = '';

  constructor(private spinner: NgxSpinnerService,
    public activatedRoute : ActivatedRoute,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.item = JSON.parse(this.activatedRoute.snapshot.paramMap.get('item'));
    console.log(this.item)
    this.spinner.show();
    this.webservice.getData('residentServiceCommentList/' + this.item._id).then((result: any) => {
      if(result.status == 1) {
        this.list = result.data;
        console.log(this.list)
        if (this.list.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      } else {
        this.noData = true;

      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });

  }

  onComment() {
    var params = {
      resident_id: this.loginData._id,
      resident_name: this.loginData.name,
      property_manager_id: this.loginData.parent_id,
      property_manager_name: this.loginData.parent_name,
      resient_service_id: this.item._id,
      resient_service_name: this.item.service,
      property_id: this.item.property_id,
      service_created_by_id: this.item.created_by_id,
      comment: this.comment,
    }
    if(this.comment == '') {
      this.toastr.error('Please enter your comment');
    } else {
      this.webservice.postData('residentServiceComment', params).then((result: any) => {
          if(result.status == 1) {
            this.comment = '';
            this.toastr.success(result.msg);
            this.ngOnInit();
          } else {
            this.toastr.error(result.msg);
          }
        this.spinner.hide;
      }, (err) => {
        this.spinner.hide;
        console.log(err);
      });
    }

  }

}
