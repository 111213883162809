import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WebserviceService } from "../api/webservice.service";

@Component({
  selector: 'app-floor-plan-list',
  templateUrl: './floor-plan-list.component.html',
  styleUrls: ['./floor-plan-list.component.scss']
})
export class FloorPlanListComponent implements OnInit {
  loginData: any = [];
  websitelink: any = "";
  type: any = "pdf";
  file: any;
  pdf_file_type: any;
  floor_pdf: any = [];
  short_descriptions: any = "";
  title: any = "";

  list: any;
  noData: boolean = false;
  constructor( public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem("LoginToken"));
    this.spinner.show();
    this.webservice.getData("getFloorplan/" + this.loginData.property_id).then(
      (result: any) => {
        console.log(result);
        if (result.status == 1) {
          this.list = result.data;
          if (this.list.length == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
        } else {
          this.noData = true;
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
    onDelete(id) {
      var params = {
        floor_plan_id: id
      }
      this.webservice.postData('deleteFloorPlan',params).then((result: any) => {
        if (result.status == 1) {
          this.ngOnInit();
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
  
      }, (err) => {
        console.log(err);
      });
  
    }
    openPdf(pdfUrl: string) {
      window.open(pdfUrl, '_blank');
    }
  }