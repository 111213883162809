import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-property-manager-message',
  templateUrl: './property-manager-message.component.html',
  styleUrls: ['./property-manager-message.component.scss']
})
export class PropertyManagerMessageComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  loginData: any = [];
  reciver_name: any = '';
  sender_name: any = '';
  reciver_id: any = '';
  sender_id: any = '';
  chat_id: any;
  messages:[];
  msg: any = '';
  cancelClicked

  constructor(public webservice: WebserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    if (this.loginData.user_type == 3) {
      this.webservice.getData('getPropertyManagerDetails/'+this.loginData.property_id).then((result: any) => {
        if (result.status == 1) {
          this.reciver_name = result.data.name;
          this.sender_name = this.loginData.name;
          this.reciver_id = result.data.property_manager_id;
          this.sender_id = this.loginData._id;
          var params = {
            property_id: this.loginData.property_id,
            sender_id: this.loginData._id,
            receiver_id: result.data.property_manager_id
          }
          this.webservice.postData('chkPropertyManagerResidentChat', params).then((result: any) => {
            if(result.status == 1) {
              console.log(result);
              this.chat_id = result.data.chat_id;
              this.onChatDetails(this.chat_id);
              this.readUnreadMessage(this.chat_id);
            } else {
  
            }
  
          }, (err) => {
            console.log(err);
          });
        }

      }, (err) => {
        console.log(err);
      });
    } else {
      this.route.queryParams.subscribe(params => {
        this.reciver_name = params.nickname;
        this.reciver_id = params.reciverId;
        this.sender_name = this.loginData.name;
        this.sender_id = this.loginData._id;


        var params1 = {
          property_id: this.loginData.property_id,
          sender_id: this.loginData._id,
          receiver_id: this.reciver_id
        }
        this.webservice.postData('chkPropertyManagerResidentChat', params1).then((result: any) => {
          if(result.status == 1) {
            console.log('chkPropertyManagerResidentChat: ',result);
            this.chat_id = result.data.chat_id;
            this.onChatDetails(result.data.chat_id);
            this.readUnreadMessage(result.data.chat_id);
          } else {

          }

        }, (err) => {
          console.log(err);
        });

      })
    }
  }

  onChatDetails(id) {
    this.webservice.getData('getPropertyManagerResidentChatList/' + id).then((result: any) => {
      console.log(result);
      if(result.status == 1) {
        this.messages = result.data
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      } else {
        this.messages=[];
        // this.webservice.presentToast(result.msg)
      }
    }, (err) => {
      console.log(err);
    });
  }

  readUnreadMessage(id) {
    var params = {
      chat_id:  id,
      user_id: this.sender_id
    }
    this.webservice.postData('readPropertyManagerResidentChat', params).then((result: any) => {
      console.log('result: ', result);
      if(result.status == 1) {
        console.log(result)
        // this.webservice.propertymanagermessageSignal.next(true);
      }

    }, (err) => {
      console.log(err);
    });
  }

  onSendMessage() {
    if(this.msg == '') {
      this.toastr.error('Enter message');
    } else {
      let date: Date = new Date();
        var params = {
          message: this.msg,
          reciever: this.reciver_name,
          sender: this.sender_name,
          created_at: date,
          sender_id: this.sender_id,
          receiver_id: this.reciver_id,
          chat_id: this.chat_id,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
        }
        this.webservice.postData('savePropertyManagerResidentChat', params).then((result: any) => {
          if(result.status == 1) {
            this.msg = '';
            this.onChatDetails(this.chat_id);
          } else {
            this.toastr.error(result.msg);
          }
        }, (err) => {
          console.log(err);
        });
    }

  }

  

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

onDelete(id) {
  this.spinner.show();
  var params = {
    _id: id
  }
  this.webservice.postData('deletePropertyManagerResidentChat', params).then((result: any) => {
    if(result.status == 1) {
      this.onChatDetails(this.chat_id);
      this.toastr.success(result.msg);
    } else {
      this.toastr.error(result.msg);
    }
    this.spinner.hide();
  }, (err) => {
    this.spinner.hide();
    console.log(err);
  });

}



}
