import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as Moment from 'moment';
import { compileNgModule } from '@angular/compiler';

@Component({
  selector: 'app-addswimmingpool',
  templateUrl: './addswimmingpool.component.html',
  styleUrls: ['./addswimmingpool.component.css']
})
export class AddswimmingpoolComponent implements OnInit {
  @ViewChild('NgbdDatepicker', { static: true }) d: NgbDateStruct;
  
  loginData: any = [];
  roomValue: any;
  title: any = '';

  sDate: any = '';
  sTime: any = '';
  eDate: any = '';
  eTime: any = '';
  suiteNo: any = '';
  message: any = '';
  noOfGuest: any = '';
  termsAndConditionsPdfLink: any = '';
  tarmsAndConditions: boolean = false;
  currentDate = new Date();
  


  buildlingName: any = '';
  guestNames: any = '';
  specialAssistanceRequest: any = '';
  minDate: { year: number; month: number; day: number; };

  constructor(public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,private config: NgbDatepickerConfig) { 
      const current = new Date();
      
  this.minDate = {
    year: current.getFullYear(),
    month: current.getMonth() + 1,
    day: current.getDate()
  };
    }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.suiteNo = this.loginData.suite_no;
    this.buildlingName = this.loginData.property_name;
    console.log('buildlingName: ', this.buildlingName);
    this.roomValue = JSON.parse(this.activatedRoute.snapshot.paramMap.get('type'));
    console.log('roomValue: ', this.roomValue)
    if (this.roomValue == 1) {
      this.title = 'Party Room';
    } else if (this.roomValue == 2) {
      this.title = 'Theatre Room';
    } else if (this.roomValue == 3) {
      this.title = 'Swimming Pool';
    } else if (this.roomValue == 4) {
      this.title = 'Elevator';
    } else if (this.roomValue == 5) {
      this.title = 'Tennis Court';
    } else if (this.roomValue == 6) {
      this.title = 'Guest Room';
    }
    this.onTermsAndConditions();
  }

  Add() {
    console.log(this.tarmsAndConditions);
    
    // Format start and end dates using Moment.js
    var StartDate = Moment(this.sDate.year + '/' + this.sDate.month + '/' + this.sDate.day).format('LL');
    var endDate = Moment(this.eDate.year + '/' + this.eDate.month + '/' + this.eDate.day).format('LL');

    // Format start and end times
    var startTime = this.sTime.hour + ':' + this.sTime.minute + ':' + this.sTime.second;
    var endTime = this.eTime.hour + ':' + this.eTime.minute + ':' + this.eTime.second;

    // Validate for roomValue == 1
    if (this.roomValue == 1) {
        if (!this.sDate) {
            this.toastr.error("Please enter start date");
        } else if (!this.sTime) {
            this.toastr.error("Please enter start time");
        } else if (!this.eDate) {
            this.toastr.error("Please enter end date");
        } else if (Moment(StartDate).isAfter(endDate, 'day')) {
            this.toastr.error("Please choose correct end date");
        } else if (!this.eTime) {
            this.toastr.error("Please enter end time");
        } else if (!this.suiteNo) {
            this.toastr.error("Please enter suite number");
        } else if (!this.noOfGuest || this.noOfGuest == null) {
            this.toastr.error("Please enter number of guests");
        } else if (!this.message) {
            this.toastr.error("Please enter message");
        } else if (!this.termsAndConditionsPdfLink) {
            this.toastr.error("Service terms & condition not uploaded. Please upload file in service terms & condition");
        } else if (!this.tarmsAndConditions) {
            this.toastr.error("Please agree to all terms and conditions");
        } else {
            var sDateTime = Moment(this.sDate.year + '-' + this.sDate.month + '-' + this.sDate.day + ' ' + this.sTime.hour + ':' + this.sTime.minute + ':' + this.sTime.second, 'YYYY-MM-DD HH:mm:ss').toDate();
            var eDateTime = Moment(this.eDate.year + '-' + this.eDate.month + '-' + this.eDate.day + ' ' + this.eTime.hour + ':' + this.eTime.minute + ':' + this.eTime.second, 'YYYY-MM-DD HH:mm:ss').toDate();

            if (sDateTime < new Date()) {
                this.toastr.error("Start time cannot be in the past");
            } else if (sDateTime >= eDateTime) {
                this.toastr.error("End time should be greater than start time");
            } else {
                var params = {
                    created_by_id: this.loginData._id,
                    created_by: this.loginData.name,
                    message: this.message,
                    start_date: StartDate,
                    start_time: Moment(sDateTime).format('LT'),
                    end_date: endDate,
                    end_time: Moment(eDateTime).format('LT'),
                    property_id: this.loginData.property_id,
                    property_name: this.loginData.property_name,
                    type: this.roomValue,
                    suite_no: this.suiteNo,
                    no_of_guest: this.noOfGuest,
                    new_format_start_time: sDateTime,
                    new_format_end_time: eDateTime
                };

                this.spinner.show();
                this.webservice.postData('bookPartyroom', params).then((result: any) => {
                    if (result.status == 1) {
                        this.sDate = '';
                        this.sTime = '';
                        this.eDate = '';
                        this.eTime = '';
                        this.message = '';
                        this.noOfGuest = '';
                        this.tarmsAndConditions = false;
                        this.toastr.success(result.msg);
                        console.log('Navigating:', this.title );
                        this.router.navigate(['home/services', JSON.stringify(this.title )]);
                       
                    } else {
                        this.toastr.error(result.msg);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    console.log(err);
                });
            }
        }
    } else if (this.roomValue != 1) {
        if (!this.sDate) {
            this.toastr.error("Please enter start date");
        } else if (!this.sTime) {
            this.toastr.error("Please enter start time");
        } else if (!this.eDate) {
            this.toastr.error("Please enter end date");
        } else if (Moment(StartDate).isAfter(endDate, 'day')) {
            this.toastr.error("Please choose correct end date");
        } else if (!this.eTime) {
            this.toastr.error("Please enter end time");
        } else if (!this.suiteNo) {
            this.toastr.error("Please enter suite number");
         } 
        else if (!this.message) {
            this.toastr.error("Please enter message");
        } else if (!this.termsAndConditionsPdfLink) {
            this.toastr.error("Service terms & condition not uploaded. Please upload file in service terms & condition");
        } else if (!this.tarmsAndConditions) {
            this.toastr.error("Please agree to all terms and conditions");
        } else {
            var sDateTime = Moment(this.sDate.year + '-' + this.sDate.month + '-' + this.sDate.day + ' ' + this.sTime.hour + ':' + this.sTime.minute + ':' + this.sTime.second, 'YYYY-MM-DD HH:mm:ss').toDate();
            var eDateTime = Moment(this.eDate.year + '-' + this.eDate.month + '-' + this.eDate.day + ' ' + this.eTime.hour + ':' + this.eTime.minute + ':' + this.eTime.second, 'YYYY-MM-DD HH:mm:ss').toDate();

            if (sDateTime < new Date()) {
                this.toastr.error("Start time cannot be in the past");
            } else if (sDateTime >= eDateTime) {
                this.toastr.error("End time should be greater than start time");
            } else {
                var params1 = {
                    created_by_id: this.loginData._id,
                    created_by: this.loginData.name,
                    message: this.message,
                    start_date: StartDate,
                    start_time: Moment(sDateTime).format('LT'),
                    end_date: endDate,
                    end_time: Moment(eDateTime).format('LT'),
                    property_id: this.loginData.property_id,
                    property_name: this.loginData.property_name,
                    type: this.roomValue,
                    suite_no: this.suiteNo,
                    no_of_guest: this.noOfGuest,
                    new_format_start_time: sDateTime,
                    new_format_end_time: eDateTime
                };

                this.spinner.show();
                this.webservice.postData('bookPartyroom', params1).then((result: any) => {
                    if (result.status == 1) {
                        this.sDate = '';
                        this.sTime = '';
                        this.eDate = '';
                        this.eTime = '';
                        this.message = '';
                        this.tarmsAndConditions = false;
                        this.toastr.success(result.msg);
                        console.log('Navigating to add-service with roomValue:', this.title );
                        this.router.navigate(['home/services', JSON.stringify(this.title )]);
                    } else {
                        this.toastr.error(result.msg);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    console.log(err);
                });
            }
        }
    }
}







AddGuestRoom() {
  if (this.buildlingName == '') {
    this.toastr.error('Enter Building Name.');
  } else if (this.suiteNo == '') {
    this.toastr.error('Enter Resident Suite No.');
  } else if (this.guestNames == '') {
    this.toastr.error('Enter Guest Names.');
  } else if (this.noOfGuest == '') {
    this.toastr.error('Enter No. of Guests.');
  } else if (this.sDate == '') {
    this.toastr.error('Enter Check In Date.');
  } else if (this.sTime == '') {
    this.toastr.error('Enter Check In Time.');
  } else if (this.eDate == '') {
    this.toastr.error('Enter Check Out Date.');
  } else if (this.eTime == '') {
    this.toastr.error('Enter Check Out Time.');
  } else if (this.message == '') {
    this.toastr.error('Enter Event Description.');
  } else if (this.termsAndConditionsPdfLink == '') {
    this.toastr.error("Service Terms & Condition Not Uploaded. Please Upload File in  Service Terms & Condition");
  } else if (this.tarmsAndConditions == false) {
    this.toastr.error("Please Read and Agreed to All Terms and Conditions");
  } else {
    // Convert dates and times
    var StartDate = Moment(this.sDate.year + '/' + this.sDate.month + '/' + this.sDate.day).format('YYYY-MM-DD');
    var endDate = Moment(this.eDate.year + '/' + this.eDate.month + '/' + this.eDate.day).format('YYYY-MM-DD');
    var startTime = this.sTime.hour + ':' + this.sTime.minute + ':' + this.sTime.second;
    var endTime = this.eTime.hour + ':' + this.eTime.minute + ':' + this.eTime.second;
  
    // Combine date and time into datetime strings
    var sDateTimeStr = StartDate + ' ' + startTime;
    var eDateTimeStr = endDate + ' ' + endTime;
  
    // Parse into Date objects
    var startDateTime = new Date(sDateTimeStr);
    var endDateTime = new Date(eDateTimeStr);
  
    // Check if start time is in the past
    if (startDateTime < new Date()) {
      this.toastr.error('CheckIn time cannot be in the past.');
    } 
    // Check if start date is greater than end date
    else if (StartDate > endDate) {
      this.toastr.error('CheckOut date should be greater than CheckIn date.');
    } 
    // Check if start date and end date are the same, but times are the same
    else if (StartDate === endDate && (startTime === endTime)) {
      this.toastr.error('CheckIn time and CheckOut time cannot be the same on the same day.');
    } 
    // Check if start date and end date are the same but start time is greater than end time
    // else if (StartDate === endDate && (startTime >= endTime)) {
    //   this.toastr.error('CheckIn time cannot be greater than or equal to CheckOut time for the same day.');
    // } 
    // Check if end date and time are less than or equal to start date and time
    else if (endDateTime <= startDateTime) {
      this.toastr.error('CheckOut date and time should be greater than CheckIn date and time.');
    } 
    else {
      console.log('OK');
  
      // Convert dates and times to the new formats
      var params1 = {
        created_by_id: this.loginData._id,
        created_by: this.loginData.name,
        message: this.message,
        start_date: Moment(startDateTime).format('LL'),
        start_time: Moment(startDateTime).format('LT'),
        end_date: Moment(endDateTime).format('LL'),
        end_time: Moment(endDateTime).format('LT'),
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        type: this.roomValue,
        suite_no: this.suiteNo,
        new_format_start_time: startDateTime,
        new_format_end_time: endDateTime,
        special_assistance: this.specialAssistanceRequest,
        guest_name: this.guestNames,
        no_of_guest: this.noOfGuest,
      }
      // Convert dates and times to the new formats
      var params1 = {
        created_by_id: this.loginData._id,
        created_by: this.loginData.name,
        message: this.message,
        start_date: Moment(startDateTime).format('LL'),
        start_time: Moment(startDateTime).format('LT'),
        end_date: Moment(endDateTime).format('LL'),
        end_time: Moment(endDateTime).format('LT'),
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        type: this.roomValue,
        suite_no: this.suiteNo,
        new_format_start_time: startDateTime,
        new_format_end_time: endDateTime,
        special_assistance: this.specialAssistanceRequest,
        guest_name: this.guestNames,
        no_of_guest: this.noOfGuest,
      };
      this.spinner.show();
      this.webservice.postData('bookPartyroom', params1).then((result: any) => {
        if (result.status == 1) {
          // Reset fields upon successful booking
          this.sDate = '';
          this.sTime = '';
          this.eDate = '';
          this.eTime = '';
          this.message = '';
          this.specialAssistanceRequest = '';
          this.guestNames = '';
          this.tarmsAndConditions = false;
          this.noOfGuest = '';
          this.toastr.success(result.msg);
          console.log('Navigating to add-service with roomValue:', this.title );
          this.router.navigate(['home/services', JSON.stringify(this.title )]);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }
}



  onTermsAndConditions() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id+"/"+this.roomValue).then((result: any) => {
      console.log('terms PDf',result);
      if(result.status == 1) {
        this.termsAndConditionsPdfLink = result.data[0].pdf_url
      } else {

      }
    }, (err) => {
      console.log(err);
    });
}
validateNoOfGuest() {
  if (this.noOfGuest < 0) {
    this.noOfGuest = 0; // Reset to 0 if a negative value is entered
  }

}
}
