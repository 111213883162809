import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WebserviceService } from "../api/webservice.service";

@Component({
  selector: "app-floor-plan",
  templateUrl: "./floor-plan.component.html",
  styleUrls: ["./floor-plan.component.scss"],
})
export class FloorPlanComponent implements OnInit {
  loginData: any = [];
  websitelink: any = "";
  type: any = "pdf";
  file: any;
  pdf_file_type: any;
  floor_pdf: any = [];
  short_descriptions: any = "";
  title: any = "";

  constructor(
    public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() 
  {
    this.loginData = JSON.parse(localStorage.getItem("LoginToken"));
    // this.spinner.show();
    // this.webservice.getData("getFloorplan/" + this.loginData.property_id).then(
    //   (result: any) => {
    //     console.log(result);
    //     if (result.status == 1) {
    //       this.websitelink = result.data[0].floor_plan_link;
    //     } else {
    //       console.log(result.msg);
    //     }
    //     this.spinner.hide();
    //   },
    //   (err) => {
    //     this.spinner.hide();
    //     console.log(err);
    //   }
    // );
  }

  onChangeType(val) {
    console.log(val);
    this.type = val;
  }

  onFileChange(event) {
    this.floor_pdf = [];
    if (!event.target.files.length) return
    this.file = event.target.files[0];
    console.log('type======',this.file)

    /////////
    if(event.target.files && event.target.files.length) {
      this.floor_pdf.push(...event.target.files)
      for(let i=0;i<event.target.files.length;i++){
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e)=>{
         // this.images.push(e.target.result)          
        }
      }
    }
    

    if (
      
      this.file.type == "application/pdf" 
      
    ) {
      this.pdf_file_type = event.target.files[0].type.split("/")[0];
      const reader = new FileReader();
      
      reader.onload = (e) => {
        console.log("eeeeeeeee=====",e)
        //this.floor_pdf = reader.result.toString();
         console.log("floor",this.floor_pdf)

      };
      reader.readAsDataURL(this.file);
    } else {
      this.toastr.error("Invalid format. Please upload PDF.");
    }
  }

  onUpdate() {
    if (this.type == "pdf") {
      if (this.title == "") {
        this.toastr.error("Please enter floor plan subject");
      } else if (this.short_descriptions == "") {
        this.toastr.error("Please enter short descriptions");
      } else if (this.file == "") {
        this.toastr.error("Please attach pdf");
      } 
      else if (this.pdf_file_type != "application") {
        console.log("file======", this.pdf_file_type);
        this.toastr.error("Please upload correct file format");
      }
       else {
        this.spinner.show();
        let fd = new FormData();
        fd.append("created_by_id", this.loginData._id);
        fd.append("created_by", this.loginData.name);
        fd.append("property_id", this.loginData.property_id);
        fd.append("property_name", this.loginData.property_name);
        fd.append("title", this.title);
        for(let i=0;i<this.floor_pdf.length;i++){
          fd.append('floor_pdf',this.floor_pdf[i])
        }
        fd.append("short_descriptions", this.short_descriptions);
        this.webservice.postData("saveFloorplanPDF", fd).then(
          (result: any) => {
            if (result.status == 1) {
              this.title = "";
              this.floor_pdf = [];
              this.short_descriptions = "";
              this.toastr.success(result.msg);
              this.router.navigate(['/floor-plan/floorPlanList']);
            } else {
              this.toastr.error(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
    } else {
      if (this.title == "") {
        this.toastr.error("Please enter floor plan subject");
      } else if (this.short_descriptions == "") {
        this.toastr.error("Please enter short description");
      } else if (this.websitelink == "") {
        this.toastr.error("Please enter library link");
      } else {
        var params = {
          created_by_id: this.loginData._id,
          created_by: this.loginData.name,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
          floor_plan_link: this.websitelink,
          title: this.title,
          short_descriptions: this.short_descriptions,
        };
        this.spinner.show();
        this.webservice.postData("saveFloorplan", params).then(
          (result: any) => {
            if (result.status == 1) {
              this.title = "";
              this.short_descriptions = "";
              this.websitelink = "";
              this.toastr.success(result.msg);
              this.router.navigate(['/floor-plan/floorPlanList']);
            } else {
              this.toastr.error(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
    }
  }
}
