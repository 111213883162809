import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from 'moment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import { EventSettingsModel, View } from '@syncfusion/ej2-schedule';
import { EJ2Instance, PopupOpenEventArgs, ScheduleComponent } from '@syncfusion/ej2-angular-schedule';
import { Button } from '@syncfusion/ej2-buttons';


@Component({
  selector: 'app-manage-memo',
  templateUrl: './manage-memo.component.html',
  styleUrls: ['./manage-memo.component.scss']
})
export class ManageMemoComponent implements OnInit {
  loginData: any;
  Button: any;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  userType: any;
  list: any = [];
  deleteId: any = '';

  public setView: View = 'Month';
  public selectedDate: Date = new Date();
  public eventObject: EventSettingsModel = {};
  dataresize: boolean = false;
  @ViewChild("scheduleObj", { static: true })
  public scheduleObj: ScheduleComponent;
  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  //   ngOnInit(): void {
  //     this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  //     if (this.loginData.user_type == 1) {
  //         var id = this.loginData._id;
  //     } else {
  //         id = this.loginData.parent_id
  //     }
  //     this.spinner.show();
  //     this.webservice.getData('memoList/' + this.loginData.property_id).then((result: any) => {
  //         var events = [];
  //         if (result.status == 1) {
  //             this.list = result.data;
  //             var currentDate = new Date(); // Get current date
  //             if (this.loginData.user_type == 1) {
  //                 var editDelete = false;
  //             } else {
  //                 editDelete = true;
  //             }
  //             for (var i = 0; i < this.list.length; i++) {
  //                 var sDateTime = this.list[i].converted_date + ' ' + this.list[i].start_time;
  //                 var eDateTime = this.list[i].formatted_end_date_old + ' ' + this.list[i].end_time;
  //                 var startDate = new Date(this.list[i].converted_date); // Convert start date to Date object
  //                 var endDate = new Date(eDateTime); // Convert end time to Date object
  //                 // Check if start date has passed
  //                 var isPastStartDate = startDate < currentDate;
  //                 // Check if end time has passed
  //                 var isPastEndDate = endDate < currentDate;
  //                 events.push({
  //                     Subject: this.list[i].subject,
  //                     StartTime: new Date(sDateTime),
  //                     EndTime: new Date(eDateTime),
  //                     IsReadonly: isPastEndDate || editDelete, // Set as readonly if past end date or according to user type
  //                     Id: this.list[i]._id,
  //                     Color: isPastStartDate ? 'red' : '' // Set color to gray if past start date, otherwise default
  //                 })
  //             }
  //             this.eventObject = {
  //                 dataSource: events
  //             };
  //             this.scheduleObj.refreshEvents();
  //         } else {
  //             console.log(result.msg)
  //         }
  //         this.spinner.hide();
  //     }, (err) => {
  //         this.spinner.hide();
  //         console.log(err);
  //     });
  // }
  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    if(this.loginData.user_type == 1) {
      var id = this.loginData._id; 
    } else {
      id = this.loginData.parent_id
    }
    this.spinner.show();
    this.webservice.getData('memoList/' + this.loginData.property_id).then((result: any) => {
      var events = [];
      if (result.status == 1) {
        this.list = result.data;
        if (this.loginData.user_type == 1) {
          var editDelete = false;
        } else {
          editDelete = true;
        }
        for (var i = 0; i<this.list.length; i++){
          var sDateTime = this.list[i].converted_date+' '+ this.list[i].start_time;
          var eDateTime = this.list[i].formatted_end_date_old+' '+ this.list[i].end_time;
          events.push({
            Subject: this.list[i].subject,
            StartTime: new Date(sDateTime),
            EndTime: new Date(eDateTime),
            IsReadonly: editDelete,
            Id: this.list[i]._id,
          })

        }

        this.eventObject  = {
          dataSource: events
       }; 
       this.scheduleObj.refreshEvents();

      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onPopupOpen(args: PopupOpenEventArgs): void {
    console.log('args: ',args)
   
    var currentDate = new Date(); // Get current date
    var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-edit" : ".e-schedule-dialog .e-event-edit";
    var buttonDelElement = args.type === "QuickInfo" ? ".e-event-popup .e-delete" : ".e-schedule-dialog .e-event-delete";
  //    let isEmptyCell = 
  //   args.target.classList.contains('e-work-cells') || 
  //   args.target.classList.contains('e-header-cells'); // checking whether the cell is empty or not 
  // if ((args.type === 'QuickInfo' || args.type === 'Editor') && isEmptyCell) { 
  //   args.cancel = true; 
  // } 
  if (args && args.target && (args.type === 'QuickInfo' || args.type === 'Editor')) {
    let isEmptyCell = args.target.classList.contains('e-work-cells') || args.target.classList.contains('e-header-cells');
    if (isEmptyCell) {
        args.cancel = true;
    }
} else {
    console.error('Invalid event arguments or missing target:', args);
  
}
    var editButton = document.querySelector(buttonElement);
    var delButton = document.querySelector(buttonDelElement);
   
    
    if(currentDate > args.data.EndTime ){
      console.log('current',currentDate)
      console.log('current11111',args.data.EndTime)
      if (editButton && (editButton as EJ2Instance).ej2_instances) {
        ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }
  
      if (delButton && (delButton as EJ2Instance).ej2_instances) {
        ((delButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }
      if (args.type == "DeleteAlert") {
        this.deleteId = args.data.Id;
        console.log(this.deleteId);
        
      }
    }
    else{
      console.log('current3123',currentDate)
      console.log('current6767',args.data.EndTime)
      if (editButton && (editButton as EJ2Instance).ej2_instances) {
        ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }
  
      if (delButton && (delButton as EJ2Instance).ej2_instances) {
        ((delButton as EJ2Instance).ej2_instances[0] as Button).disabled = false;
      }

      if (args.type == "DeleteAlert") {
        this.deleteId = args.data.Id;
        console.log(this.deleteId);
       
       
      }
      if( args.type == "Editor" && args.data.Id != undefined) {
        console.log('edit')
        let navihationExtras: NavigationExtras = {
          queryParams: {
            bookingId: args.data.Id,
          }
        }
        this.router.navigate(['home/memo-edit'], navihationExtras);
        this.ngOnInit();
      }
    }
  }
  

  // onPopupOpen_(args: PopupOpenEventArgs): void {
  //   console.log('args: ',args)
  //     if (args.type == "DeleteAlert") {
  //       this.deleteId = args.data.Id;
  //       console.log(this.deleteId)
  //     } 
      
  //     // console.log(args.type);

  //     if( args.type == "Editor" && args.data.Id != undefined) {
  //       console.log('edit')
  //       let navihationExtras: NavigationExtras = {
  //         queryParams: {
  //           bookingId: args.data.Id,
  //         }
  //       }
  //       this.router.navigate(['home/memo-edit'], navihationExtras);
  //       this.ngOnInit();
  //     }
  //     if (args.type == 'QuickInfo'  && args.data.Id == undefined) {
  //       this.ngOnInit();
  //     }
    
  // }


  // oneventRendered(event) {
  //   console.log('oneventRendered: ',event)
  //   if(this.deleteId != '') {
  //     console.log(this.deleteId)
  //     // Delete functionlity
  //     var params = {
  //       memo_id: this.deleteId
  //     }
  //     this.webservice.postData('memoDelete', params).then((result: any) => {
  //       if (result.status == 1) {
  //         this.ngOnInit();
  //         this.deleteId = '';
  //         this.toastr.success(result.msg);
  //       } else {
  //         this.deleteId = '';
  //         this.toastr.error(result.msg);
  //       }
  //       this.spinner.hide();
  //     }, (err) => {
  //       this.spinner.hide();
  //       console.log(err);
  //     });
  //   }

  // }

  oneventRendered(event) {
    console.log(this.deleteId);
    if (this.deleteId != "") {
      console.log(this.deleteId);
      var params = {
        memo_id: this.deleteId
      }
      this.webservice.postData('memoDelete', params).then((result: any) => {
        if (result.status == 1) {
          this.ngOnInit();
          this.deleteId = '';
          this.toastr.success(result.msg);
        } else {
          this.deleteId = '';
          this.toastr.error(result.msg);
        }
      }, (err) => {
      });
    }

  }


  




  onAdd() {
    // this.router.navigate(['add-service',JSON.stringify(this.roomValue)]);
    this.router.navigate(['home/add-memo'])
  }


  //calender

}



