import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-announcement-edit',
  templateUrl: './announcement-edit.component.html',
  styleUrls: ['./announcement-edit.component.scss']
})
export class AnnouncementEditComponent implements OnInit {
  loginData: any;
  id: any;
  title: any = '';
  description: any = '';
  data: any= [];

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.spinner.show();
    this.webservice.getData('getAnnouncementById/'+ this.id).then((result: any) => {
      if (result.status == 1) {
        this.data = result.data;
        console.log(this.data);
        this.title = this.data.title;
        this.description = this.data.description;
      } else {
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onEdit() {
    if (this.title == '') {
      this.toastr.error('Please enter title');
    } else if(this.description == '') {
      this.toastr.error('Please enter description');
    } else {
      var params = {
        announcement_id : this.id,
        title: this.title,
        description: this.description
      }
      this.spinner.show();
      this.webservice.postData('updateAnnouncement',params).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

}
