import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-resident-details',
  templateUrl: './resident-details.component.html',
  styleUrls: ['./resident-details.component.scss']
})
export class ResidentDetailsComponent implements OnInit {
    id: any;
    loginData: any;

  fName: any = '';
  lName: any = '';
  bDate: any = '';
  bMonth: any = '';
  bYear: any = '';
  gender: any = '';
  email
  cellNumber;
  country: any = '';
  state: any = '';
  city: any = '';
  suiteNo: any = '';
  address: any = '';
  postalCode: any = '';
  eName
  eEmail: any = '';
  eCellNo
  eReletation: any = '';
  condoname: any = '';
  residentType: any = '';
  homeNo: any = '';
  buildingName: any = '';
  adult_status:any='';
  country_code:any = '';
  homeCountryCode:any = '';
  contactCountryCode:any = '';

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.id = JSON.parse(this.activatedRoute.snapshot.paramMap.get('id'));
    console.log(this.id)
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));

    this.spinner.show();
    this.webservice.getData('userProfile/' + this.id).then((result: any) => {
      if (result.status == 1) {
        console.log('result: ',result);
        // this.userType = result.data.user_type;
        this.fName = result.data.name.split(' ').slice(0, -1).join(' ');
        this.lName = result.data.name.split(' ').slice(-1).join(' ');
        this.email = result.data.email;
        this.cellNumber = result.data.contact_no;
        this.suiteNo = result.data.suite_no;
        this.postalCode = result.data.postal_code;
        this.eEmail = result.data.emergency_contact_email;
        this.eName = result.data.emergency_contact_name;
        this.eCellNo = result.data.emergency_contact_phone_no;
        this.eReletation = result.data.emergency_contact_relationship,
          this.gender = result.data.gender;
        this.address = result.data.address;
        this.country = result.data.country;
        this.state = result.data.province;
        this.city = result.data.city;
        this.bDate = result.data.b_date;
        this.bMonth = result.data.birth_month;
        this.bYear = result.data.b_year;
        this.condoname = result.data.condo_name;
        this.residentType = result.data.resident_type;
        this.homeNo = result.data.home_no;
        this.buildingName = result.data.property_name;
        this.adult_status = result.data.adult_status;
        this.country_code =result.data.country_code;
        this.homeCountryCode=result.data.homeCountryCode;
        this.contactCountryCode =result.data. contactCountryCode;


      } else {
        console.log('error: ', result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

}
