import { Component, OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { StripeService, StripeCardComponent } from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";

import { NgxSpinnerService } from "ngx-spinner";
import { WebserviceService } from '../api/webservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";

@Component({
  selector: "app-pay",
  templateUrl: "./pay.component.html",
  styleUrls: ["./pay.component.scss"],
})
export class PayComponent implements OnInit {
  UserData: any;
  stripe_token: any = '';
  charge_amount:any = '';
  user_id:any = '';
  subscription_amount: any= '';
  // propertyManager_id:any;
  // subscriptionamount:any;
  @ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };

  stripeTest: FormGroup;
 

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private spinner: NgxSpinnerService,
    public webservice: WebserviceService,
    private toastr: ToastrService, 
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.UserData = JSON.parse(localStorage.getItem("LoginToken"));
   
   
    // this.spinner.show();
    // this.propertyManager_id = this.UserData._id
    // console.log('user data===========',this.UserData)
    // this.amount();
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
      charge_amount: ["", [Validators.required]],
    });
  

  }

  createToken(): void {

    const name = this.stripeTest.get("name").value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log(result.token.id);
          this.stripe_token = result.token.id;
          this.charge_amount = this.UserData.subscription_amount;
          this.user_id = this.UserData._id
          this.oncard();
        } else if (result.error) {
          // Error creating the token
          this.toastr.error(result.error.message);
          console.log(result.error.message);
        }
      });
  }
  oncard() {
    var params = {
      stripe_token:this.stripe_token,
      charge_amount:this.charge_amount,
      user_id:this.user_id,


    }
    console.log("params", params);

    this.spinner.show();
    this.webservice.postData('payment', params).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.stripe_token= '';
        this.charge_amount= '';
        this.user_id= '';

        this.toastr.success(result.msg);
  
        this.router.navigate(['/home']);



      } 
      
      else if(result.status == 0){
        this.toastr.error(result.msg);
      }
      
      
      
      else {
        this.toastr.error(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
}
