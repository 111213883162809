import { Component, OnInit } from '@angular/core';
import { WebserviceService } from '../api/webservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-subscriptioncheck',
  templateUrl: './subscriptioncheck.component.html',
  styleUrls: ['./subscriptioncheck.component.scss']
})
export class SubscriptioncheckComponent implements OnInit {
  UserData: any;
  subscription_amount: any = '';
  subscription_start_date: any = '';
  subscription_end_date: any = '';

  constructor( private spinner: NgxSpinnerService,
    public webservice: WebserviceService,
    private toastr: ToastrService, ) { }

  ngOnInit() {
    this.UserData = JSON.parse(localStorage.getItem("LoginToken"));
    this.subscriptionList();
  }
  subscriptionList() {
    this.spinner.show();
    this.webservice
      .getData('getSubscriptionDetails/' + this.UserData._id)
      .then(
        (result: any) => {
          console.log('subscription List', result);

          if (result.status === 1) {
            this.subscription_amount = result.data.subscription_amount;
            this.subscription_start_date = result.data.subscription_start_date;
            this.subscription_end_date = result.data.subscription_end_date;
           
          } 
          else {
            console.log(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }
  

}
