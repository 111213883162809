import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-service-approval',
  templateUrl: './service-approval.component.html',
  styleUrls: ['./service-approval.component.scss']
})
export class ServiceApprovalComponent implements OnInit {
  loginData: any = [];
  noDataFound: boolean = false;
  list: any = [];
  cancelClicked
  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.loginData)
    if (this.loginData.user_type == 1 || this.loginData.user_type == 3) {
      //property manager id
      var type = 'non_resident'
    } else {
      type = 'resident'
      // id
    }
     this.spinner.show();
      this.webservice.getData('bookingLists/'+this.loginData.property_id+'/'+this.loginData._id+'/'+type).then((result: any) => {
        console.log(result)
        if(result.status == 1) {
          this.list = result.data;
          this.noDataFound = false;
        } else {
          this.noDataFound = true;
        }
        this.spinner.hide();
      }, (err) => {
        this.noDataFound = true;
        this.spinner.hide();
        console.log(err);
      });
  }


  onApprove(id) {
    console.log(id);
    var params = {
      booking_id: id
    }
    this.webservice.postData('approveBooking', params).then((result: any) => {
      if(result.status == 1) {
        this.toastr.success(result.msg);
        this.ngOnInit();
      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      this.noDataFound = true;
      console.log(err);
    });
  }

  onRejected(id) {
    console.log(id);
    var params = {
      booking_id: id
    }
    this.webservice.postData('denyBooking', params).then((result: any) => {
      if(result.status == 1) {
        this.toastr.success(result.msg);
        this.ngOnInit();
      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      this.noDataFound = true;
      console.log(err);
    });
  }
  onDelete(id)
 {
    var params = {
      booking_id: id
    }
    this.webservice.postData('deleteBooking',params).then((result: any) => {
      if (result.status == 1) {
        
        this.toastr.success(result.msg);
        this.ngOnInit();
      } else {
        this.toastr.error(result.msg);
      }

    }, (err) => {
      console.log(err);
    });

  } 
}
