import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-concierge-message',
  templateUrl: './concierge-message.component.html',
  styleUrls: ['./concierge-message.component.scss']
})
export class ConciergeMessageComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  loginData: any = [];

  msg: any = '';
  resultData: any;
  chat_id: any;
  receiver_id: any;
  reciver_name: any;
  sender_id: any;
  sender_name: any;
  messages: any;

  constructor(public webservice: WebserviceService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    if (this.loginData.user_type == 2 || this.loginData.user_type == 4) {
      var params = {
        property_id: this.loginData.property_id,
        sender_id: this.loginData._id
      }
      this.webservice.postData('chkResidentStaffChat', params).then((result) => {
        this.resultData = result;
        if (this.resultData.status == 1) {
          console.log(this.resultData.data);
          this.chat_id = this.resultData.data.chat_id;
          this.receiver_id = this.resultData.data.receiver_id;
          this.reciver_name = this.resultData.data.receiver;
          this.chatDetails(this.chat_id);
          this.readUnreadMessage();
        } else {

        }

      }, (err) => {
        console.log(err);
      });
    } else if (this.loginData.user_type == 3) {
      this.sender_id = this.loginData._id;
      this.sender_name = this.loginData.name;
      this.route.queryParams.subscribe(params => {
        console.log(params);
        this.reciver_name = params.nickname;
        this.receiver_id = params.reciverId;
      })
      var params1 = {
        property_id: this.loginData.property_id,
        sender_id: this.loginData._id,
        receiver_id: this.receiver_id
      }

      this.webservice.postData('chkStaffResidentChat', params1).then((result) => {
        this.resultData = result;
        if (this.resultData.status == 1) {
          console.log(this.resultData.data);
          this.chat_id = this.resultData.data.chat_id;
          this.chatDetails(this.chat_id);
          this.readUnreadMessage();
        } else {

        }

      }, (err) => {
        console.log(err);
      });

    }

  }

  readUnreadMessage() {
    var params = {
      chat_id:  this.chat_id,
      user_id: this.sender_id
    }
    this.webservice.postData('readStaffResidentChat', params).then((result: any) => {
      if(result.status == 1) {
      }

    }, (err) => {
      console.log(err);
    });
  }

  chatDetails(id) {
    this.webservice.getData('getStaffResidentChatList/' + id).then((result: any) => {
      if(result.status == 1) {
        this.messages = result.data
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      } else {
        console.log(result.msg)
        // this.webservice.presentToast(this.data.msg)
      }
    }, (err) => {
      console.log(err);
    });

  }

  onSendMessage() {
    if(this.msg == '') {
      this.toastr.error('Enter message');
    } else {
      let date: Date = new Date();
      var params = {
        sender_id: this.sender_id,
        sender: this.sender_name,
        receiver_id: this.receiver_id,
        reciever: this.reciver_name,
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        chat_id: this.chat_id,
        message: this.msg,
        created_at: date
      }
      console.log(params)
      this.webservice.postData('saveStaffResidentChat', params).then((result: any) => {

        if (result.status == 1) {
          this.msg = '';
          this.chatDetails(this.chat_id);
        } else {
          this.toastr.error(result.msg)
        }

      }, (err) => {
        console.log(err);
      });
    }
  }



  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

}
