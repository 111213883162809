import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  termsAndConditions: boolean = false;
  eReletation: any = '';
  residentType: any = '';
  fName: any = '';
  lName: any = '';
  bDate: any = '';
  bMonth: any = '';
  bYear: any = '';
  gender: any = '';
  email: any = '';
  cellNumber: any = '';
  country: any = '';
  state: any = '';
  city: any = '';
  condoName: any = '';
  suiteNo: any = '';
  address: any = '';
  postalCode: any = '';
  password: any = '';
  cPassword: any = '';
  eName: any = '';
  eEmail: any = '';
  eCellNo: any = '';
  countryList: any = [];
  cityList: any = [];
  stateList: any = [];
  consoNameList: any = [];

  birthDate = [{ date: '1' }, { date: '2' }, { date: '3' }, { date: '4' }, { date: '5' }, { date: '6' }, { date: '7' }, { date: '8' }, { date: '9' }, { date: '10' }, { date: '11' }, { date: '12' }, { date: '13' }, { date: '14' }, { date: '15' }, { date: '16' }, { date: '17' }, { date: '18' }, { date: '19' }, { date: '20' }, { date: '1' }, { date: '21' }, { date: '22' }, { date: '23' }, { date: '24' }, { date: '25' }, { date: '26' }, { date: '27' }, { date: '28' }, { date: '29' }, { date: '30' }, { date: '31' }];
  birthMonth = [{ month: 'Jan' }, { month: 'Feb' }, { month: 'Mar' }, { month: 'Apr' }, { month: 'May' }, { month: 'Jun' }, { month: 'Jul' }, { month: 'Aug' }, { month: 'Sep' }, { month: 'Oct' }, { month: 'Nov' }, { month: 'Dec' },];
  birthYear: any = [];

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    for (var i = (new Date()).getFullYear() - 100; i <= (new Date()).getFullYear(); i++) {
      this.birthYear.push({ year: i });
    }
    this.allCountryList();
  }

  onCheckTarmsAndConditions() {
    if (this.termsAndConditions == false) {
      this.termsAndConditions = true;
    } else {
      this.termsAndConditions = false;
    }
  }

  onChangeReletation(val) {
    console.log(val)
    if (val == 'friend') {
      this.eReletation = 'friend';
    } else {
      this.eReletation = 'family';
    }
  }
  onChangeResidentType(val) {
    this.residentType = val;
  }
  onChangeDate(val) {
    this.bDate = val;
  }

  onChangeMonth(val) {
    this.bMonth = val;
  }

  onChangeYear(val) {
    this.bYear = val;
  }

  onChangeGender(val) {
    this.gender = val;
  }

  allCountryList() {
    this.webservice.getData('countryList').then((result: any) => {
      if (result.status == 1) {
        this.countryList = result.data;
        console.log('countryList: ', this.countryList);
      } else {
        console.log(result.msg);
      }
    }, (err) => {
      console.log(err);
    });

  }

  OnChangeCountry(val) {
    this.country = val;
    console.log(this.country)
    if (this.country != '') {
      this.spinner.show();
      this.webservice.getData('stateList/' + this.country).then((result: any) => {
        if (result.status == 1) {
          this.stateList = result.data;
        } else {
          console.log(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

  OnChangeState(val) {
    this.state = val;
    console.log(this.state)
    if (this.state != '') {
      this.webservice.getData('cityList/' + this.state).then((result: any) => {
        if (result.status == 1) {
          this.cityList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  onChangeCity(val) {
    this.city = val;
    if (this.city != '') {
      this.webservice.getData('propertyListByCity/' + this.city).then((result: any) => {
        console.log(result)
        if (result.status == 1) {
          this.consoNameList = result.data;
        } else {
          console.log(result.msg);
        }
      }, (err) => {
        console.log(err);
      });

    }
  }

  onChangeCondoName(val) {
    this.condoName = val;
  }

  onSignUp() {
    
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.residentType == '') {
      this.toastr.error('Please select resident status');
    } else if (this.fName == '') {
      this.toastr.error('Please enter first name');
    } else if (this.lName == '') {
      this.toastr.error('Please enter last name');
    } else if (this.bDate == '' || this.bMonth == '' || this.bYear == '') {
      this.toastr.error('Please select birthday');
    } else if (this.gender == '') {
      this.toastr.error('Please select gender');
    } else if (this.email == '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Enter valid email')
    } else if (this.cellNumber == '') {
      this.toastr.error('Please enter cell number');
    } else if (this.country == '') {
      this.toastr.error('Please select country');
    } else if (this.state == '') {
      this.toastr.error('Please select state');
    } else if (this.city == '') {
      this.toastr.error('Please select city');
    } else if (this.condoName == '') {
      this.toastr.error('Please select condo name');
    } else if (this.suiteNo == '') {
      this.toastr.error('Please enter suite number');
    } else if (this.address == '') {
      this.toastr.error('Please enter address');
    } else if (this.postalCode == '') {
      this.toastr.error('Please enter postal code');
    } else if (this.password == '') {
      this.toastr.error('Please enter password');
    } else if (this.cPassword == '') {
      this.toastr.error('Please enter confirm password');
    } else if (this.password != this.cPassword) {
      this.toastr.error('Confirm password does not match the password');
    } else if (this.termsAndConditions == false) {
      this.toastr.error('Please read and agreed to all Terms and Conditions and Privacy Policy');
    } else {
      var params = {
        name: this.fName + ' ' + this.lName,
        birthday: this.bMonth + '-' + this.bDate + '-' + this.bYear,
        gender: this.gender,
        email: this.email,
        contact_no: this.cellNumber,
        suite_no: this.suiteNo,
        country_id: this.country,
        state_id: this.state,
        city_id: this.city,
        address: this.address,
        postal_code: this.postalCode,
        emergency_contact_name: this.eName,
        emergency_contact_email: this.eEmail,
        emergency_contact_phone_no: this.eCellNo,
        emergency_contact_relationship: this.eReletation,
        password: this.password,
        property_id: this.condoName,
        birth_month: this.bMonth,
        resident_type: this.residentType
      }
      console.log(params)

      this.spinner.show();
      this.webservice.postData('saveResident', params).then((result: any) => {
        if (result.status == 1) {
          this.fName = '';
          this.lName = '';
          this.gender = '';
          this.bMonth = '';
          this.bDate = '';
          this.bYear = '';
          this.email = '';
          this.cellNumber = '';
          this.suiteNo = '';
          this.country = '';
          this.state = '';
          this.city = '';
          this.postalCode = '';
          this.eName = '';
          this.eEmail = '';
          this.eCellNo = '';
          this.eReletation = '';
          this.password = '';
          this.cPassword = '';
          this.condoName = '';
          this.residentType = '';
          this.eReletation = '';
          this.termsAndConditions = false;
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
    }
  }

}
