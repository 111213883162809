import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss']
})
export class EditServiceComponent implements OnInit {
  loginData: any = [];
  roomValue: any;
  suiteNo: any = '';
  title: any = '';

  sDate: any = '';
  sTime: any = '06:02';
  eDate: any = '';
  eTime: any = '07:05';
  message: any = '';
  noOfGuest: any = '';
  termsAndConditionsPdfLink: any = '';
  bookingId: any;
  resultData: any = [];

  buildlingName: any = '';
  guestNames: any = '';
  specialAssistanceRequest: any = '';

  currentDate:any =new Date();

  constructor(public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) {

  }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('params: ', params);
      this.bookingId = params.bookingId;
      console.log('bookingId: ',this.bookingId);
      this.roomValue = params.Type;
      console.log('roomValue: ', this.roomValue)
      if (this.roomValue == 1) {
        console.log(this.roomValue)
        this.title = 'Party Room';
      } else if (this.roomValue == 2) {
        console.log(this.roomValue)
        this.title = 'Theatre Room';
      } else if (this.roomValue == 3) {
        console.log(this.roomValue)
        this.title = 'Swimming Pool';
      } else if (this.roomValue == 4) {
        console.log(this.roomValue)
        this.title = 'Elevator';
      } else if (this.roomValue == 5) {
        console.log(this.roomValue)
        this.title = 'Tennis Court';
      } else if (this.roomValue == 6) {
        this.title = 'Guest Room';
      }

    })

    this.spinner.show();
    this.webservice.getData('getBookingDetails/' + this.bookingId).then((result: any) => {
      console.log(result)
      if (result.status == 1) {
        this.buildlingName = result.data.property_name;
        this.resultData = result.data;
        this.sDate = result.data.formatted_start_date;
        this.sTime =  moment(result.data.start_time, "h:mm:ss A").format("HH:mm");
        this.eDate = result.data.formatted_end_date;
        this.eTime = moment(result.data.end_time, "h:mm:ss A").format("HH:mm");
        this.suiteNo = result.data.suite_no;
        this.noOfGuest = result.data.no_of_guest;
        this.message = result.data.message;
        var sDateTime = new Date(result.data.formatted_start_date + ' ' +  result.data.start_time);
        console.log(result.data.start_time)
        console.log(moment(result.data.start_time, "h:mm:ss A").format("HH:mm:ss"))
        this.specialAssistanceRequest = result.data.special_assistance;
        this.guestNames = result.data.guest_name;

        this.spinner.hide();
      } else {

        this.spinner.hide();
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onEdit() {
    console.log(this.sTime);
    var StartDate = moment(this.sDate).format('LL');
    var endDate = moment(this.eDate).format('LL');
    var startTime = moment(new Date(StartDate + ' ' + this.sTime)).format('LT');
    var endTime = moment(new Date(endDate + ' ' + this.eTime)).format('LT');

    var params = {
        booking_id: this.bookingId,
        start_date: StartDate,
        start_time: startTime,
        end_date: endDate,
        end_time: endTime,
        property_id: this.resultData.property_id,
        type: this.resultData.type,
        message: this.message,
        new_format_start_time: new Date(StartDate + ' ' + this.sTime),
        new_format_end_time: new Date(endDate + ' ' + this.eTime),
        suite_no: this.suiteNo,
        no_of_guest: this.noOfGuest
    };

    if (this.roomValue == 1) {
        if (this.sDate == '') {
            this.toastr.error("Please enter start date");
        } else if (this.sTime == '') {
            this.toastr.error("Please enter start time");
        } else if (this.eDate == '') {
            this.toastr.error("Please enter end date");
        }   else if (endDateTime < startDateTime) {
          this.toastr.error("End date cannot be before start date");
        } 
       
        else if (this.eTime == '') {
            this.toastr.error("Please enter end time");
        } else if (this.suiteNo == '') {
            this.toastr.error("Please enter suite number");
        } else if (this.noOfGuest == '' || this.noOfGuest == null) {
            this.toastr.error("Please enter number of guests");
        } else if (this.message == '') {
            this.toastr.error("Please enter message");
        } else {
            var startDateTime = new Date(StartDate + ' ' + this.sTime);
            var endDateTime = new Date(endDate + ' ' + this.eTime);

            if (startDateTime < new Date()) {
              this.toastr.error("Start time cannot be in the past");
            } 
            
            else if (startDateTime >= endDateTime) {
              this.toastr.error("End time should be greater than start time");
            } else if (startDateTime.getTime() === endDateTime.getTime()) {
              this.toastr.error("Start time and end time cannot be equal");
            }
            else {
                this.spinner.show();
                this.webservice.postData('updatePartyRoomBooking', params).then((result: any) => {
                    if (result.status == 1) {
                        this.toastr.success(result.msg);
                    } else {
                        this.toastr.error(result.msg);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    console.log(err);
                });
            }
        }
    } else if (this.roomValue != 1) {
        if (this.sDate == '') {
            this.toastr.error("Please enter start date");
        } else if (this.sTime == '') {
            this.toastr.error("Please enter start time");
        } else if (this.eDate == '') {
            this.toastr.error("Please enter end date");
        }
        else if (endDateTime < startDateTime) {
          this.toastr.error("End date cannot be before start date");
        } 
        else if (this.eTime == '') {
            this.toastr.error("Please enter end time");
        } else if (this.suiteNo == '') {
            this.toastr.error("Please enter suite number");
        } else if (this.message == '') {
            this.toastr.error("Please enter message");
        } else {
          var startDateTime = new Date(StartDate + ' ' + this.sTime);
          var endDateTime = new Date(endDate + ' ' + this.eTime);

          if (startDateTime < new Date()) {
            this.toastr.error("Start time cannot be in the past");
          }
          else if (startDateTime >= endDateTime) {
            this.toastr.error("End time should be greater than start time");
          } else if (startDateTime.getTime() === endDateTime.getTime()) {
            this.toastr.error("Start time and end time cannot be equal");
          }
           else {
            this.spinner.show();
            this.webservice.postData('updatePartyRoomBooking', params).then((result: any) => {
                if (result.status == 1) {
                    this.toastr.success(result.msg);
                } else {
                    this.toastr.error(result.msg);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                console.log(err);
            });
        }
    }
}
  }


  onEditGuestRoom() {
    if (this.buildlingName == '') {
      this.toastr.error('Enter building name.');
    } else if (this.suiteNo == '') {
      this.toastr.error('Enter resident suite no.');
    } else if (this.guestNames == '') {
      this.toastr.error('Enter guest names.');
    } else if (this.noOfGuest == '') {
      this.toastr.error('Enter No. of Guests.');
    } else if (this.sDate == '') {
      this.toastr.error('Enter check In date.');
    } else if (this.sTime == '') {
      this.toastr.error('Enter check In time.');
    } else if (this.eDate == '') {
      this.toastr.error('Enter check Out date.');
    } else if (this.eTime == '') {
      this.toastr.error('Enter check Out time.');
    } else if (this.message == '') {
      this.toastr.error('Enter event description.');
    } else {
      var StartDate = moment(this.sDate).format('LL');
      var endDate = moment(this.eDate).format('LL');
      var startTime = moment(new Date(StartDate + ' ' + this.sTime)).format('LT')
      var endTime = moment(new Date(endDate + ' ' + this.eTime)).format('LT')
  
      // Additional checks based on your requirements
      var currentDate = moment().format('YYYY-MM-DD'); // Current date in YYYY-MM-DD format
      var currentDateTime = moment().format('YYYY-MM-DD HH:mm'); // Current date and time in YYYY-MM-DD HH:mm format
  
      if (this.sDate > this.eDate) {
        this.toastr.error('Enter correct check out date');
      } else if (this.sDate == this.eDate && startTime >= endTime) {
        this.toastr.error('Check out time must be greater than check in time on the same day.');
      } else if (this.sDate == currentDate && startTime < moment().format('HH:mm')) {
        this.toastr.error('Check in time cannot be in the past for today\'s date.');
      } else {
        var params = {
          booking_id: this.bookingId,
          start_date: StartDate,
          start_time: startTime,
          end_date: endDate,
          end_time: endTime,
          property_id: this.resultData.property_id,
          type: this.resultData.type,
          message: this.message,
          new_format_start_time: new Date(StartDate + ' ' + this.sTime),
          new_format_end_time: new Date(endDate + ' ' + this.eTime),
          suite_no: this.suiteNo,
          no_of_guest: this.noOfGuest,
          special_assistance: this.specialAssistanceRequest,
          guest_name: this.guestNames,
        };
  
        this.spinner.show();
        this.webservice.postData('updatePartyRoomBooking', params).then((result: any) => {
          if (result.status == 1) {
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        }).catch((err) => {
          this.spinner.hide();
          console.error(err);
          this.toastr.error('Failed to update booking. Please try again.');
        });
      }
    }
  }
  
  
  validateNoOfGuest() {
    if (this.noOfGuest < 0) {
      this.noOfGuest = 0; // Reset to 0 if a negative value is entered
    }
  
  }
}
