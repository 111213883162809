import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-librarylist',
  templateUrl: './librarylist.component.html',
  styleUrls: ['./librarylist.component.scss']
})
export class LibrarylistComponent implements OnInit {
  list: any;
  noData: boolean = false;
  loginData: any;
  
  constructor(public webservice: WebserviceService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show()
    this.webservice.getData('libraryList/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        if (this.list.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      } else {
        this.noData = true;
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onDelete(id) {
    var params = {
      library_id: id
    }
    this.webservice.postData('deleteLibrary',params).then((result: any) => {
      if (result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }

    }, (err) => {
      console.log(err);
    });

  }
  openPdf(pdfUrl: string) {
    window.open(pdfUrl, '_blank');
  }
}
