import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  loginData: any;
  list: any = [];
  nodataFound: boolean = false;
  cancelClicked
  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getAnnouncement/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        console.log('list: ',this.list);
        this.nodataFound = false;
        this.readAnnouncement();
      } else {
        this.nodataFound = true;
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  readAnnouncement() {
      var params = {
        user_id: this.loginData._id
      }
      this.webservice.postData('readAnnouncementNotification', params).then((result: any) => {
        if(result.status == 1) {
          console.log('sucess');
        }
      }, (err) => {
        console.log(err);
      });
  }

  onDelete(id) {
    console.log(id)
    var params = {
      announcement_id: id
    }
    this.webservice.postData('deleteAnnouncement', params).then((result: any) => {
      if (result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }
    }, (err) => {
      console.log(err);
    });
  }

  onEdit(id) {
    this.router.navigate(['home/edit-announcement',id]);
  }

  onAdd() {
    this.router.navigate(['home/add-announcement']);
  }
}
