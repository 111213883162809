import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AddmemoComponent } from './addmemo/addmemo.component';
import { AddswimmingpoolComponent } from './addswimmingpool/addswimmingpool.component';
import { AnnouncementAddComponent } from './announcement-add/announcement-add.component';
import { AnnouncementEditComponent } from './announcement-edit/announcement-edit.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { BudlingContactComponent } from './budling-contact/budling-contact.component';
import { BusinessComponent } from './business/business.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CommentComponent } from './comment/comment.component';
import { ConciergeMessageListComponent } from './concierge-message-list/concierge-message-list.component';
import { ConciergeMessageComponent } from './concierge-message/concierge-message.component';
import { CondoByLawsComponent } from './condo-by-laws/condo-by-laws.component';
import { DiaryComponent } from './diary/diary.component';
import { EditServiceComponent } from './edit-service/edit-service.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FlashDealsComponent } from './flash-deals/flash-deals.component';
import { FloorPlanListComponent } from './floor-plan-list/floor-plan-list.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { FloorPlaneditComponent } from './floor-planedit/floor-planedit.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LibraryeditComponent } from './libraryedit/libraryedit.component';
import { LibrarylistComponent } from './librarylist/librarylist.component';
import { LibrayComponent } from './libray/libray.component';
import { LoginComponent } from './login/login.component';
import { ManageMemoComponent } from './manage-memo/manage-memo.component';
import { MemoDetailsComponent } from './memo-details/memo-details.component';
import { MemoEditComponent } from './memo-edit/memo-edit.component';
import { ParceldeliveryComponent } from './parceldelivery/parceldelivery.component';
import { PayComponent } from './pay/pay.component';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { PropertyManagerMessageListComponent } from './property-manager-message-list/property-manager-message-list.component';
import { PropertyManagerMessageComponent } from './property-manager-message/property-manager-message.component';
import { RegisterComponent } from './register/register.component';
import { ResidentDetailsComponent } from './resident-details/resident-details.component';
import { ResidentdirectoryComponent } from './residentdirectory/residentdirectory.component';
import { ResidentpostingComponent } from './residentposting/residentposting.component';
import { ResourcesComponent } from './resources/resources.component';
import { ServiceApprovalComponent } from './service-approval/service-approval.component';
import { ServiceBoockedComponent } from './service-boocked/service-boocked.component';
import { ServiceTermsAndConditionsComponent } from './service-terms-and-conditions/service-terms-and-conditions.component';
import { ServicesComponent } from './services/services.component';
import { SettingComponent } from './setting/setting.component';
import { StaffDirectoryComponent } from './staff-directory/staff-directory.component';
import { SubscriptioncheckComponent } from './subscriptioncheck/subscriptioncheck.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { VisitorEPassComponent } from './visitor-e-pass/visitor-e-pass.component';
import { ForgetPassowrdPageComponent } from './forget-passowrd-page/forget-passowrd-page.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/add-service/:type', component: AddswimmingpoolComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'signup', component: RegisterComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'services1/:type', component: ServicesComponent },
  { path: 'home/services/:type', component: ServiceBoockedComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'diary', component: DiaryComponent },
  { path: 'home/resident-posting', component: ResidentpostingComponent },
  { path: 'home/comment/:item', component: CommentComponent },
  { path: 'home/resident-directory', component: ResidentdirectoryComponent },
  { path: 'home/staff-directory', component: StaffDirectoryComponent},
  { path: 'home/resident-details/:id', component: ResidentDetailsComponent },
  { path: 'home/staff-details/:id', component: StaffDetailsComponent },
  { path: 'home/manage-memo', component: ManageMemoComponent },
  { path: 'memo-details/:date', component: MemoDetailsComponent },
  { path: 'home/add-memo', component: AddmemoComponent },
  { path: 'home/setting', component: SettingComponent },
  { path: 'home/add-announcement', component: AnnouncementAddComponent },
  { path: 'home/announcement', component: AnnouncementComponent },
  { path: 'home/edit-announcement/:id', component: AnnouncementEditComponent },
  { path: 'home/visitor-e-pass', component: VisitorEPassComponent },
  { path: 'home/concierge-message-list', component: ConciergeMessageListComponent },
  { path: 'home/property-manager-message-list', component: PropertyManagerMessageListComponent },
  { path: 'home/property-manager-message', component: PropertyManagerMessageComponent },
  { path: 'home/concierge-message', component: ConciergeMessageComponent },
  { path: 'home/parcel-delivery', component: ParceldeliveryComponent },
  { path: 'condo-by-laws', component: CondoByLawsComponent },
  { path: 'floor-plan', component: FloorPlanComponent },
  { path: 'library', component: LibrayComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'home/service-approval', component: ServiceApprovalComponent },
  { path: 'service-terms-and-conditions', component: ServiceTermsAndConditionsComponent },
  { path: 'building-contact', component: BudlingContactComponent },
  { path: 'home/edit-service', component: EditServiceComponent },
  { path: 'home/memo-edit', component: MemoEditComponent },
  { path: 'pay', component:PayComponent },
  { path: 'subscriptioncheck', component:SubscriptioncheckComponent},
  { path: 'dailydeals', component:FlashDealsComponent},
  { path: 'library/libraryList', component:LibrarylistComponent},
  { path: 'library/libraryList/:_id', component:LibraryeditComponent},
  { path:'floor-plan/floorPlanList',component:FloorPlanListComponent},
  { path:'floor-plan/floorPlanList/:_id', component:FloorPlaneditComponent},
  {path:'forget', component:ForgetPassowrdPageComponent}
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
