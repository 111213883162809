import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { WebserviceService } from '../api/webservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginData: any;
  constructor(public webservice: WebserviceService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    
    console.log(this.loginData)
   }

  ngOnInit(): void {
    // if(localStorage.getItem('LoginToken')){
      this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
      console.log(this.loginData)
    // }
  }
  onLogout(){
    {
    
        var params = {
        device_id: 'deviceId',
         user_id: this.loginData._id
        };
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Logout?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.webservice.postData('userLogout', params).then((result: any) => {
      
              console.log('result: ',result);
              if(result.status == 1) {
                localStorage.removeItem('LoginToken');
                this.toastr.success(result.msg);
                this.router.navigate(['login']);
              } else {
                this.toastr.error(result.msg);
              }
              this.spinner.hide();
            }, (err) => {
              this.spinner.hide();
              console.log(err);
            });
      }
    })



  }
   
  };


}

