import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
import * as Moment from 'moment';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss']
})
export class DiaryComponent implements OnInit {
  date: any;
  type: any;
  title: any;
  loginData: any;
  list: any = [];

  constructor(public activatedRoute : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      this.date = params.date;
      this.type = params.type;
      if (this.type == 1) {
        this.title = 'Party Room';
      } else if (this.type == 2) {
        this.title = 'Theatre Room';
      } else if (this.type == 3) {
        this.title = 'Swimming Pool';
      }
      var date = Moment(this.date).format("YYYY-MM-DD");
      console.log(date)

      this.spinner.show();
      this.webservice.getData('getBookingDiary/' + this.loginData.property_id+'/'+ this.type+'/'+this.date).then((result: any) => {
        if(result.status == 1) {
          this.list = result.data;
          console.log(this.list)
        } else {

        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });

    })
  }

}
