import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-residentdirectory',
  templateUrl: './residentdirectory.component.html',
  styleUrls: ['./residentdirectory.component.css']
})
export class ResidentdirectoryComponent implements OnInit {
  loginData: any;
  list: any = [];
  search: any = '';
  cancelClicked
  nodataFound: boolean = false;
  titel: any = '';
  constructor(public webservice: WebserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.titel = params.Type;
    })
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.loginData)
    this.spinner.show();
    this.webservice.getData('getResidentsByProperty/' + this.loginData.property_id+'/undefined').then((result: any) => {
      if (result.status == 1) {
        this.list = result.data;
        this.nodataFound = false;
        console.log("content: ", this.list.length);
      } else {
        this.nodataFound = true;
        console.log(result.msg);
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.nodataFound = true;
      console.log(err);
    });
  }

  onDelete(id) {
    var params = {
      user_id: id
    }
    this.webservice.postData('deleteResident',params).then((result: any) => {
      if (result.status == 1) {
        this.ngOnInit();
        this.toastr.success(result.msg);
      } else {
        this.toastr.error(result.msg);
      }

    }, (err) => {
      console.log(err);
    });

  }

  onSearch(value) {
    this.search = value;
    if (this.search != '') {
      this.webservice.getData('getResidentsByProperty/' + this.loginData.property_id+'/'+this.search).then((result: any) => {
        if (result.status == 1) {
          this.list = result.data;
          console.log("content: ", this.list);
          this.nodataFound = false;
        } else {
          this.nodataFound = true;
          // this.webservice.presentToast(result.msg);
        }
      }, (err) => {
        this.nodataFound = true;
        console.log(err);
      });

    } else {
      this.search = '';
      this.ngOnInit();
    }
  }

  onDetails(id) {
    this.router.navigate(['home/resident-details',JSON.stringify(id)]);

  }
  onParcelScreen(id) {
    let navihationExtras: NavigationExtras = {
      queryParams: {
        id: id,
      }
    }
    this.router.navigate(['home/parcel-delivery'], navihationExtras);
  }
  hideEmail(email: string): string {
    const atIndex = email.indexOf('@');
    if (atIndex >= 0) {
      const maskedPart = '*'.repeat(atIndex);
      return maskedPart + email.slice(atIndex);
    }
    return email;
  }
  

}
