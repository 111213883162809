import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-condo-by-laws',
  templateUrl: './condo-by-laws.component.html',
  styleUrls: ['./condo-by-laws.component.scss']
})
export class CondoByLawsComponent implements OnInit {
  loginData: any = [];
  websitelink: any = '';

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.spinner.show();
    this.webservice.getData('getCondoByLaw/' + this.loginData.property_id).then((result: any) => {
      console.log(result);
      this.spinner.hide();
      if (result.status == 1) {
        this.websitelink = result.data[0].condo_law_link_show;
      console.log(this.websitelink);
        
      } else {
        console.log(result.msg);

      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  onUpdate() {
    if (this.websitelink == '') {
      this.toastr.error('Please enter website link');
    } else {
        var params = {
          created_by_id: this.loginData._id,
          created_by: this.loginData.name,
          property_id: this.loginData.property_id,
          property_name: this.loginData.property_name,
          condo_law_link: this.websitelink
        }
        this.spinner.show();
        this.webservice.postData('saveCondoByLaw', params).then((result: any) => {
          if(result.status == 1) {
            this.toastr.success(result.msg);
          } else {
            this.toastr.error(result.msg);
          }
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          console.log(err);
        });
    }
  }

}
