import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';
declare var intlTelInput: any;
@Component({
  selector: 'app-budling-contact',
  templateUrl: './budling-contact.component.html',
  styleUrls: ['./budling-contact.component.scss']
})
export class BudlingContactComponent implements OnInit {
  managerNo: any = '';
  secutityNo: string;
  additional_information: any = '';
  email: any = '';
  whatsapp_no: string;
  whatsappCountryCode:any = '';
  contactCountryCode:any = '';
  securityCountryCode:any = '';

  Mon: any = '';
  Tue: any = '';
  Wed: any = '';
  Thu: any = '';
  Fri: any = '';
  Sat: any = '';
  Sun: any = '';
  loginData: any  = [];
  userType: any;
  itil: any;
  itilSecurity: any;
  itilmanager: any;
  shortcountrycode:any;
  constructor(public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) {
      setTimeout(() => {
      this.getcountry();
    },500);
     }

  ngOnInit() {
    
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    console.log(this.loginData);
    this.userType = this.loginData.user_type;
    console.log(this.userType);
      setTimeout(() => {
        this.itil = intlTelInput(document.querySelector("#phone"), {
          initialCountry: this.shortcountrycode,
          separateDialCode: true,
          utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
        });
        // this.itil.setNumber('+918622042108');
        this.itilSecurity = intlTelInput(document.querySelector("#securityPhone"), {
          initialCountry: this.shortcountrycode,
          separateDialCode: true,
          utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
        });
        this.itilmanager = intlTelInput(document.querySelector("#managerPhone"), {
          initialCountry: this.shortcountrycode,
          separateDialCode: true,
          utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js'
        });
    
        
        this.getcontact_details();
      },1000);
      

  
  
  }
  getcountry(){
    this.webservice.getcountry().subscribe((res:any)=>{  
      //this.ipAddress=res.ip;  
      var short_code= res.countryCode
      this.shortcountrycode = (short_code).toLowerCase();
      console.log('country===========',this.shortcountrycode)
    }); 
  }

  getcontact_details(){
    this.spinner.show();
    this.webservice.getData('getContactus/' + this.loginData.property_id).then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.managerNo = result.data.property_manager_number;
        this.secutityNo = result.data.security_number;
        this.additional_information = result.data.aditional_information;
        this.email = result.data.mail;
        this.whatsapp_no = result.data.whatsapp_no;
        this.Mon = result.data.monday;
        this.Tue = result.data.tuesday;
        this.Wed = result.data.wednesday;
        this.Thu = result.data.thursday;
        this.Fri = result.data.friday;
        this.Sat = result.data.saturday;
        this.Sun = result.data.sunday;
        this.itilmanager.setNumber(result.data.contactCountryCode+result.data.property_manager_number);
        this.itilSecurity.setNumber(result.data.securityCountryCode+result.data.security_number);
        this.itil.setNumber(result.data.whatsappCountryCode+result.data.whatsapp_no);
        
        
        
        
        console.log(result.data.contactCountryCode+result.data.property_manager_number)
      } else {

      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    })
  }

  onUpdate () {
    var selectedCountryData = this.itil.getNumber();
   // var dialCode = selectedCountryData.dialCode;
    var code = this.itil.getSelectedCountryData().dialCode;
    var codeLength= code.length+1;
      console.log(selectedCountryData);
    console.log(code)
    console.log('******',)
    this.whatsapp_no = selectedCountryData.substring(codeLength);
  console.log('******',this.whatsapp_no)
////////
var selectedCountryData1 = this.itilSecurity.getNumber();
    //var dialCode = selectedCountryData.dialCode;
    var code1 = this.itilSecurity.getSelectedCountryData().dialCode;
    var codeLength1= code1.length+1;
    //  console.log(selectedCountryData1);
    //console.log(dialCode)
    //console.log('******',)
    this.secutityNo = selectedCountryData1.substring(codeLength1);
    // console.log('******',this.secutityNo);
    /////////////////////////
    var selectedCountryData2 = this.itilmanager.getNumber();
    //var dialCode = selectedCountryData.dialCode;
    var code2 = this.itilmanager.getSelectedCountryData().dialCode;
    var codeLength2= code2.length+1;
    //  console.log(selectedCountryData2);
    //console.log(dialCode)
    //console.log('******',)
    this.managerNo = selectedCountryData2.substring(codeLength2);
    // console.log('******',this.managerNo);

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var rem = /(^[0-9]{10}$)/;
    if (this.managerNo == '' || this.managerNo == null) {
      this.toastr.error("Please enter property manager contact number");
    } else if (this.secutityNo == '' || this.secutityNo == null) {
      this.toastr.error("Please enter secutity contact number");
    } 
    else if (this.additional_information == '') {
      this.toastr.error("Please enter additional information");
    }
     else if (this.email == '') {
      this.toastr.error("Please enter email");
    } 
    else if (!re.test(this.email)) {
      this.toastr.error("Please enter valid email");
    } else if (this.whatsapp_no == '' || this.whatsapp_no == null) {
      this.toastr.error("Please enter whatsapp number");
    } 
    else if (this.Mon == '') {
      this.toastr.error("Please enter Monday");
    } else if (this.Tue == '') {
      this.toastr.error("Please enter Tuesday");
    } else if (this.Wed == '') {
      this.toastr.error("Please enter Wednesday");
    } else if (this.Thu == '') {
      this.toastr.error("Please enter Thursday");
    } else if (this.Fri == '') {
      this.toastr.error("Please enter Friday");
    } else if (this.Sat == '') {
      this.toastr.error("Please enter Saturday");
    } else if (this.Sun == '') {
      this.toastr.error("Please enter Sunday");
    }  else if (!rem.test(this.managerNo)) {
      this.toastr.error('Manager phone number should contain 10 digits.Please enter valid phone number. ');
    }
    else if (!rem.test(this.secutityNo)) {
      this.toastr.error('Security number should contain 10 digits.Please enter valid number. ');
    }
    else if (!rem.test(this.whatsapp_no)) {
      this.toastr.error('Whatsapp number should contain 10 digits.Please enter valid number. ');
    }
    else {
      var params = {
        created_by_id: this.loginData._id,
        created_by: this.loginData.name,
        property_id: this.loginData.property_id,
        property_name: this.loginData.property_name,
        property_manager_number: this.managerNo,
        
        security_number: this.secutityNo,
        aditional_information: this.additional_information,
        whatsapp_no: this.whatsapp_no,
        
        mail: this.email,
        monday: this.Mon,
        tuesday: this.Tue,
        wednesday: this.Wed,
        thursday: this.Thu,
        friday: this.Fri,
        saturday: this.Sat,
        sunday: this.Sun,
        contactCountryCode : '+' + code2,//manager
        securityCountryCode: '+' + code1, // security
        whatsappCountryCode: '+' + code,//whatsapp


      }
      console.log(params);
      this.spinner.show();
      this.webservice.postData('saveContactus', params).then((result: any) => {
        if (result.status == 1) {
          this.toastr.success(result.msg);
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      })

    }

  }

}
