import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loginData: any = [];
  userData: any = [];
  dynamicValue: any = [];
  announcementCount: any;
  announcementCountData: any = 0;
  VisitorpassCountData: any = 0;
  VisitorpassCount: any;
  ConciergeCount: any;
  countData: any = 0;
  PropertyManagerChatCountData: any = 0;
  PropertyManagerChatCount: any;
  parcelData: any = 0;
  parcelCount: any;
  approvalCountData: any = 0;
  approvalCount: any;

  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    location: PlatformLocation,
    public webservice: WebserviceService,
    private router: Router,
  ) {
  
  }


  ngOnInit(): void {
    this.spinner.show();
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
  //   setInterval(function(){ 
  //     console.log('worked')
  // }, 5000);
    this.onAnnouncementCount();
    this.visitorPassCountFunction();
    this.ConciergeChatCount();
    this.onPropertyManagerChatCount();
    this.onParcelCount();
    this.approvalServiceCount();

   

    console.log(this.loginData)
    this.webservice.getData('userProfile/' + this.loginData._id).then((result: any) => {
      if (result.status == 1) {
        this.DynamicMenuData();
        this.userData = result.data;
        console.log(this.userData);
      } else {
        console.log('error: ', result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }
  DynamicMenuData() {
    this.webservice.getData('getMenu/' + this.loginData.property_id).then((result: any) => {
      if (result.status == 1) {
        console.log(result.data);
        this.dynamicValue = result.data;
      } else {
        console.log(result.msg)
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  onSwimmingPool(val) {
    this.router.navigate(['home/services', JSON.stringify(val)]);
  }

  // onResources() {
  //   this.router.navigate(['home/resources']);
  // }
  // onFlashDeal() {
  //   this.router.navigate(['home/dailydeals']);
  // }

  onResidentPosting() {
    this.router.navigate(['home/resident-posting']);
  }

  onResidentDirectory() {
    // this.router.navigate(['resident-directory']);
    let navihationExtras: NavigationExtras = {
      queryParams: {
        Type: 'Resident Directory'
      }
    }
    this.router.navigate(['home/resident-directory'], navihationExtras);
  }
  onStaffDirectory(){
 
    let navihationExtras: NavigationExtras = {
      queryParams: {
        Type: 'Staff Directory'
      }
    }
    this.router.navigate(['home/staff-directory'], navihationExtras);
  }

  onManageMemo() {
    this.router.navigate(['/home/manage-memo']);
  }

  onSetting() {
    this.router.navigate(['/home/setting']);
  }

  onAnnouncement() {
    this.router.navigate(['/home/announcement']);
  }

  onVisitorEpass() {
    this.router.navigate(['home/visitor-e-pass']);
  }
  onConciergeMessage() {
    this.router.navigate(['home/concierge-message-list']);
  }
  onPropertyManagerMessage(){
    if (this.loginData.user_type == 1) {
      this.router.navigate(['home/property-manager-message-list']);
    } else {
      this.router.navigate(['home/property-manager-message']);
    }
  }

  onParcelDelivery() {
    // this.router.navigate(['parcel-delivery']);
    // this.router.navigate(['resident-directory']);
    let navihationExtras: NavigationExtras = {
      queryParams: {
        Type: 'Users'
      }
    }
    this.router.navigate(['home/resident-directory'], navihationExtras);
  }

  onServiceApproval() {
    this.router.navigate(['home/service-approval']);
  }

  onAnnouncementCount() {
    console.log('_id: ', this.loginData)
    this.webservice.getData('getAnnouncementCount/' + this.loginData._id).then((result: any) => {
      console.log('announcementCountData: ', result.data)
      this.announcementCountData = result.data;
      if (result.data > 9) {
        this.announcementCount = "9+";
      } else if (result.data < 9) {
        this.announcementCount = result.data;
      }
    }, (err) => {
      console.log(err);
    });
  }

  approvalServiceCount() {
    var userType =this.loginData.user_type;
    if (userType == 1 || userType == 3) {
      //property manager id
      var type = 'non_resident'
    } else {
       type = 'resident'
      // id
    }
    this.webservice.getData('getBookingCount/'+ this.loginData.property_id+'/'+this.loginData._id+'/'+type).then((result: any) => {
      this.approvalCountData = result.data;

      if(this.approvalCountData > 9) {
        this.approvalCount = "9+";
      } else if(this.approvalCountData < 9) {
        this.approvalCount = this.approvalCountData;
      }

    }, (err) => {
      console.log(err);
    });
  }

  visitorPassCountFunction() {
    this.webservice.getData('epassCount/'+ this.loginData.property_id).then((result: any) => {
      this.VisitorpassCountData = result.data;
      if(result.data > 9) {
        this.VisitorpassCount = '9+'
      } else if(result.data < 9) {
        this.VisitorpassCount = result.data;
      }
    }, (err) => {
      console.log(err);
    });

  }

  ConciergeChatCount() {
      this.webservice.getData('staffResidentChatCount/'+ this.loginData._id).then((result: any) => {
        this.countData = result.data;
          if(result.data > 9) {
            this.ConciergeCount = "9+";
          } else if(result.data < 9) {
            this.ConciergeCount = result.data;
          }
      }, (err) => {
        console.log(err);
      });
  }

  onPropertyManagerChatCount() {
      this.webservice.getData('propertyManagerResidentChatCount/'+ this.loginData._id).then((result: any) => {
        console.log('propertyManagerResidentChatCount: ',result);
          this.PropertyManagerChatCountData = result.data;
          if(result.data > 9) {
            this.PropertyManagerChatCount = '9+';
          } else if(result.data < 9){
            this.PropertyManagerChatCount = result.data;
          }
      }, (err) => {
        console.log(err);
      });
  }

  onParcelCount() {
      this.webservice.getData('parcelMsgCount/'+ this.loginData._id).then((result: any) => {
        this.parcelData = result.data;
          if(result.data > 9) {
            this.parcelCount = "9+";
          } else if(result.data < 9) {
            this.parcelCount = result.data;
          }
      }, (err) => {
        console.log(err);
      });
    }

  

}
