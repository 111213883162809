import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-service-terms-and-conditions',
  templateUrl: './service-terms-and-conditions.component.html',
  styleUrls: ['./service-terms-and-conditions.component.scss']
})
export class ServiceTermsAndConditionsComponent implements OnInit {
  theaterRoom: any;
  swimmingPool: any;
  partyPdfUrl: any = '';
  theaterPdfUrl: any = '';
  swimmingPdfUrl: any = '';
  partyRoom: any;
  loginData: any = [];
  Ppdf: any = '';
  Spdf: any = '';
  Tpdf: any = '';
  ePdf: any = '';
  file: any;

  ElevatorImg: any='';
  ElevatorPdfUrl: any = '';
  Tennis_Court: any = '';
  Tennis_CourtPdf: any = '';
  TensPdf: any  = '';
  Gpdf: any = '';
  Guest_Room: any = '';
  Guest_RoomPdfUel: any ='';
  pdf_file_type: any = "";
  






  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router,) { }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('LoginToken'));
    this.getTheaterRoom();
    this.getSwimmingPool();
    this.getPartyRoom();
    this.getElevator();
    this.getTenuisCourt();
    this.getGuestRoom();
  }

  getSwimmingPool() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/3").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.swimmingPool = result.data[0].pdf_image;
        this.swimmingPdfUrl = result.data[0].pdf_url;
        // console.log('swimmingPool: ', this.swimmingPdfUrl)

      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }

  getPartyRoom() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/1").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.partyRoom = result.data[0].pdf_image;
        console.log('this.partyRoom: ', result.data[0].pdf_image)
        this.partyPdfUrl = result.data[0].pdf_url;
      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }
  getTheaterRoom() {
    this.spinner.show();
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/2").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.theaterRoom = result.data[0].pdf_image;
        this.theaterPdfUrl = result.data[0].pdf_url;
        // console.log('theaterRoom: ', this.theaterRoom.pdf_url)

      } else {

      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  getElevator() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/4").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.ElevatorImg = result.data[0].pdf_image;
        this.ElevatorPdfUrl = result.data[0].pdf_url;
        // console.log('swimmingPool: ', this.swimmingPool.pdf_url)

      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }


  getTenuisCourt() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/5").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.Tennis_Court = result.data[0].pdf_image;
        this.Tennis_CourtPdf = result.data[0].pdf_url;
        // console.log('swimmingPool: ', this.swimmingPool.pdf_url)

      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }

  getGuestRoom() {
    this.webservice.getData("bookingTermsCondition/" + this.loginData.property_id + "/6").then((result: any) => {
      console.log(result);
      if (result.status == 1) {
        this.Guest_Room = result.data[0].pdf_image;
        this.Guest_RoomPdfUel = result.data[0].pdf_url;
        // console.log('swimmingPool: ', this.swimmingPool.pdf_url)

      } else {

      }
    }, (err) => {
      console.log(err);
    });
  }

  onFileChange(event) {

    if (!event.target.files.length) return;
    this.file = event.target.files[0];
    console.log('file==',this.file)
    if (
      
      this.file.type == "application/pdf"
     
    ) {
      this.pdf_file_type = event.target.files[0].type.split("/")[0];
    
    const reader = new FileReader();
    reader.onload = e => {
      // console.log(e.target.result.toString())
      // this.pdf = reader.result.toString();
    };
    reader.readAsDataURL(this.file);
    // console.log(type);
  } else {
    this.toastr.error("Invalid format. Please upload PDF.");
  }
 
 
  //   this.spinner.show();
  //   let fd = new FormData();
  //   fd.append('created_by_id', this.loginData._id);
  //   fd.append('created_by', this.loginData.name);
  //   fd.append('property_id', this.loginData.property_id);
  //   fd.append('property_name', this.loginData.property_name);
  //   fd.append('type', type);
  //   fd.append('terms_pdf', this.file);
  
  //   this.webservice.postData('savebookingTermsCondition', fd).then((result: any) => {
  //     if(result.status == 1) {
  //       this.Ppdf = '';
  //       this.Spdf = '';
  //       this.Tpdf = '';
  //       this.file = '';
  //       this.ePdf = '';
  //       this.TensPdf = '';
  //       this.Gpdf = '';
  //       this.toastr.success(result.msg);
  //     } else {
  //       this.toastr.error(result.msg);
  //     }
  //     this.spinner.hide();
  //   }, (err) => {
  //     this.spinner.hide();
  //     console.log(err);
  //   });
  }
  onUpdate(type) {
      if ( this.pdf_file_type != "application"){
      console.log('file======',this.pdf_file_type)
         this.toastr.error('Please upload correct file format');
       }
    else {
      this.spinner.show();
     let fd = new FormData();
     fd.append('created_by_id', this.loginData._id);
     fd.append('created_by', this.loginData.name);
     fd.append('property_id', this.loginData.property_id);
     fd.append('property_name', this.loginData.property_name);
     fd.append('type',type);
     fd.append('terms_pdf', this.file);
  
     this.webservice.postData('savebookingTermsCondition', fd).then((result: any) => {
       if(result.status == 1) {
         this.Ppdf = '';
         this.Spdf = '';
         this.Tpdf = '';
         this.file = '';
         this.ePdf = '';
         this.TensPdf = '';
         this.Gpdf = '';
         this.toastr.success(result.msg);
       } else {
         this.toastr.error(result.msg);
       }
       this.spinner.hide();
     }, (err) => {
       this.spinner.hide();
       console.log(err);
     });
    }}}
