import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WebserviceService } from "../api/webservice.service";
import * as Moment from "moment";

@Component({
  selector: "app-addmemo",
  templateUrl: "./addmemo.component.html",
  styleUrls: ["./addmemo.component.css"],
})
export class AddmemoComponent implements OnInit {
  @ViewChild("NgbdDatepicker", { static: true }) d: NgbDateStruct;
  memoType: any = "all";
  subject: any = "";
  sDate: any = "";
  sTime: any = "";
  eDate: any = "";
  eTime: any = "";
  message: any = "";
  loginData: any;
  file: any;
  pdf: any = "";
  minDate: { year: number; month: number; day: number };
  pdf_file_type: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  list: any = [];
  nodataFound: boolean = false;
  name: any = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private toastr: ToastrService,
    public webservice: WebserviceService,
    private router: Router
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem("LoginToken"));
    this.webservice
      .getData(
        "getResidentsByProperty/" + this.loginData.property_id + "/undefined"
      )
      .then(
        (result: any) => {
          if (result.status == 1) {
            this.dropdownList = result.data;
            this.nodataFound = false;
            console.log("content: ", this.list.length);
          } else {
            this.nodataFound = true;
            console.log(result.msg);
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.nodataFound = true;
          console.log(err);
        }
      );
    this.dropdownSettings = {
      singleSelection: false,
      idField: "_id",
      textField: "resident_details",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onChangeType(val) {
    if (val == "mail") {
      this.memoType = "mail";
    } else if (val == "all") {
      this.memoType = "all";
    }
  }
  addMemo() {
    console.log("eTime.hour: ", this.eTime.hour);
    var startTime =
      this.sTime.hour + ":" + this.sTime.minute + ":" + this.sTime.second;
    var endTime =
      this.eTime.hour + ":" + this.eTime.minute + ":" + this.eTime.second;
    var StartDate = Moment(
      this.sDate.year + "/" + this.sDate.month + "/" + this.sDate.day
    ).format("LL");
    var endDate = Moment(
      this.eDate.year + "/" + this.eDate.month + "/" + this.eDate.day
    ).format("LL");
    var start_time = Moment(new Date(StartDate + " " + startTime)).format("LT");
    var end_time = Moment(new Date(endDate + " " + endTime)).format("LT");

    var sDateTime = new Date(this.sDate.year, this.sDate.month - 1, this.sDate.day, this.sTime.hour, this.sTime.minute, this.sTime.second);
    var eDateTime = new Date(this.eDate.year, this.eDate.month - 1, this.eDate.day, this.eTime.hour, this.eTime.minute, this.eTime.second);
    console.log('new=====',new Date())
    console.log('start=====',sDateTime)
    if (this.memoType == "all") {
      if (this.subject == "") {
        this.toastr.error("Enter subject");
      } else if (this.sDate == "") {
        this.toastr.error("Enter start date");
      } else if (this.sTime == "") {
        this.toastr.error("Enter start time");
      } else if (this.eDate == "") {
        this.toastr.error("Enter end date");
      } else if (this.eTime == "") {
        this.toastr.error("Enter end time");
      } else if (this.message == "") {
        this.toastr.error("Enter message");
      } else if (sDateTime < new Date()) {
        this.toastr.error("Start time cannot be in the past");
      } 
      else if (eDateTime < sDateTime) {
        this.toastr.error("End date cannot be before start date");
      } 
      else if (sDateTime >= eDateTime) {
        this.toastr.error("End time should be greater than start time");
      } 
      else if (sDateTime.getTime() === eDateTime.getTime()) {
       
        this.toastr.error("Start time and end time cannot be equal");
      } else {
        this.spinner.show();
        let fd = new FormData();
        fd.append("created_by_id", this.loginData._id);
        fd.append("created_by", this.loginData.name);
        fd.append("property_id", this.loginData.property_id);
        fd.append("property_name", this.loginData.property_name);
        fd.append("subject", this.subject);
        fd.append("message", this.message);
        fd.append("date_required", StartDate);
        fd.append("time", start_time);
        fd.append("end_date", endDate);
        fd.append("end_time", end_time);
        fd.append("start_time", start_time);
        fd.append("memo_doc", this.file);
        console.log(fd);
        this.webservice.postData("saveMemoType1", fd).then(
          (result: any) => {
              if (result.status == 1) {
                  this.toastr.success(result.msg);
                  // Reset form fields
                  this.subject = "";
                  this.sDate = "";
                  this.eDate = "";
                  this.sTime = "";
                  this.eTime = "";
                  this.message = "";
                  this.pdf = "";
      
                  // Reset file input
                  const fileInput = <HTMLInputElement>document.querySelector('input[type="file"]');
                  fileInput.value = '';
      
                  // Redirect to /manage-memo
                  this.router.navigate(['/home/manage-memo']);
              } else {
                  this.toastr.error(result.msg);
              }
              this.spinner.hide();
          },
          (err) => {
              this.spinner.hide();
              console.log(err);
          }
        );
      }
    } else if (this.memoType == "mail") {
      if (this.subject == "") {
        this.toastr.error("Enter subject");
      } else if (this.name == "") {
        this.toastr.error("Please select resident");
      } else {
        this.spinner.show();
        let fd = new FormData();
        fd.append("created_by_id", this.loginData._id);
        fd.append("created_by", this.loginData.name);
        fd.append("property_id", this.loginData.property_id);
        fd.append("property_name", this.loginData.property_name);
        fd.append("subject", this.subject);
        fd.append("memo_doc", this.file);
        fd.append("userIds", this.name);

        this.webservice.postData("saveMemoType2", fd).then(
          (result: any) => {
            if (result.status == 1) {
              this.toastr.success(result.msg);
              this.name = "";
              this.subject = "";
              this.pdf = "";
              this.router.navigate(['/home/manage-memo']);
            } else {
              this.toastr.error(result.msg);
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
      }
    }
}




  

  // addMemo() {
  //   console.log("eTime.hour: ", this.eTime.hour);
  //   var startTime =
  //     this.sTime.hour + ":" + this.sTime.minute + ":" + this.sTime.second;
  //   var endTime =
  //     this.eTime.hour + ":" + this.eTime.minute + ":" + this.eTime.second;
  //   var StartDate = Moment(
  //     this.sDate.year + "/" + this.sDate.month + "/" + this.sDate.day
  //   ).format("LL");
  //   var endDate = Moment(
  //     this.eDate.year + "/" + this.eDate.month + "/" + this.eDate.day
  //   ).format("LL");
  //   var start_time = Moment(new Date(StartDate + " " + startTime)).format("LT");
  //   var end_time = Moment(new Date(endDate + " " + endTime)).format("LT");
  //   if (this.memoType == "all") {
  //     if (this.subject == "") {
  //       this.toastr.error("Enter subject");
  //     } else if (this.sDate == "") {
  //       this.toastr.error("Enter start date");
  //     } else if (this.sTime == "") {
  //       this.toastr.error("Enter start time");
  //     } else if (this.eDate == "") {
  //       this.toastr.error("Enter end date");
  //     } else if (StartDate > endDate) {
  //       this.toastr.error("Enter Correct end Date");
  //     } else if (this.eTime == "") {
  //       this.toastr.error("Enter end time");
  //     } else if (this.message == "") {
  //       this.toastr.error("Enter message");
  //     } 
  //     // else if (this.pdf == "") {
  //     //   this.toastr.error("Attach PDF file");
  //     // } 
  //     // else if (this.pdf_file_type != "application") {
  //     //   console.log("file======", this.pdf_file_type);
  //     //   this.toastr.error("Please upload correct File Format");
  //     // } 
  //     else {
  //       this.spinner.show();
  //       let fd = new FormData();
  //       fd.append("created_by_id", this.loginData._id);
  //       fd.append("created_by", this.loginData.name);
  //       fd.append("property_id", this.loginData.property_id);
  //       fd.append("property_name", this.loginData.property_name);
  //       fd.append("subject", this.subject);
  //       fd.append("message", this.message);
  //       fd.append("date_required", StartDate);
  //       fd.append("time", start_time);
  //       fd.append("end_date", endDate);
  //       fd.append("end_time", end_time);
  //       fd.append("start_time", start_time);
  //       fd.append("memo_doc", this.file);
  //       console.log(fd);
  //       this.webservice.postData("saveMemoType1", fd).then(
  //         (result: any) => {
  //           if (result.status == 1) {
  //             this.toastr.success(result.msg);
  //             this.subject = "";
  //             this.sDate = "";
  //             this.eDate = "";
  //             this.sTime = "";
  //             this.eTime = "";
  //             this.message = "";
  //             this.pdf = "";
  //           } else {
  //             this.toastr.error(result.msg);
  //           }
  //           this.spinner.hide();
  //         },
  //         (err) => {
  //           this.spinner.hide();
  //           console.log(err);
  //         }
  //       );
  //     }
  //   } else if (this.memoType == "mail") {
  //     if (this.subject == "") {
  //       this.toastr.error("Enter subject");
  //     } else if (this.name == "") {
  //       this.toastr.error("Please Select resident");
  //     }
  //     // else if (this.pdf == "") {
  //     //   this.toastr.error("Attach PDF file");
  //     // } 
  //     else {
  //       this.spinner.show();
  //       let fd = new FormData();
  //       fd.append("created_by_id", this.loginData._id);
  //       fd.append("created_by", this.loginData.name);
  //       fd.append("property_id", this.loginData.property_id);
  //       fd.append("property_name", this.loginData.property_name);
  //       fd.append("subject", this.subject);
  //       fd.append("memo_doc", this.file);
  //       fd.append("userIds", this.name);

  //       this.webservice.postData("saveMemoType2", fd).then(
  //         (result: any) => {
  //           if (result.status == 1) {
  //             this.toastr.success(result.msg);
  //             this.subject = "";
  //             this.pdf = "";
  //             this.name = "";
  //           } else {
  //             this.toastr.error(result.msg);
  //           }
  //           this.spinner.hide();
  //         },
  //         (err) => {
  //           this.spinner.hide();
  //           console.log(err);
  //         }
  //       );
  //     }
  //   }
  // }

  // data() {
  //   let fd=new FormData();
  //   fd.append('created_by_id',this.UserData._id);
  //   fd.append('created_by',this.UserData.name);
  //   fd.append('title',this.title);
  //   fd.append('description',this.description);
  //   fd.append('web_link',this.web_link);
  //   fd.append('advertise_image',this.file);
  //   this.webservice.adminPostData('saveAdvertise', fd).then((result) => {
  // }

  onFileChange(event) {
    if (!event.target.files.length) return;
    this.file = event.target.files[0];
    if (
      
      this.file.type == "application/pdf"
    
    ) {
      this.pdf_file_type = event.target.files[0].type.split("/")[0];
      console.log("file", this.file);

      const reader = new FileReader();
      reader.onload = (e) => {
        //console.log(e.target.result)
        this.pdf = reader.result.toString();
        console.log("pdf", this.pdf);
      };
      reader.readAsDataURL(this.file);
    } else {
      this.toastr.error("Invalid format. Please upload PDF.");
    }
  }
  onItemSelect(item: any) {
    console.log("item==", item);
    this.name.push(item._id);
    console.log("name===", this.name);
  }
  onSelectAll(items: any) {
    console.log("items==", items);
    console.log(this.selectedItems);
    for (var i = 0; i < items.length; i++) {
      this.name.push(items[i]._id);
    }
    console.log("OnselectAll===", this.name);
  }
  onDeSelectItem(item: any) {
    console.log("desectitem==", item);
    this.name.splice(item,1);
    console.log("delete name data array--", this.name);
  }
  onDeSelectAll(items: any){
      this.name.splice(items._id);
    
    console.log("OnDelectAll===", this.name);
}
  
}
